.container {
    border-top: 1px solid #1a1a1a21;
    padding: 0;
    background-color: #fffef2;
    max-width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 12;
}


.row {
    max-width: 1170px;margin: 0 auto;
    justify-content: space-between;
}

.notification {
    background-color: #f02849;position: relative; border-radius: 50%;height: 10px; width: 10px;right: 2px;
    top: -9px;
}

.title {
    font-size: 8px;text-align: center;
    padding: 0 20px;
}

.text {
    padding: 0 20px;
}

.apply-btn {
    font-size: 8px;text-align: center;color: white;font-weight: 600;
}

@media (max-width: 767px) {
    .title {
        padding: 0;
    }
}
