html {
    scroll-behavior: smooth;
}

body {
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #4C4C4C;
    background-color: #fffef2;
}
main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

img {
    max-width: 100%;
    cursor: pointer !important;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

a, button {
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

i,
span,
a {
    display: inline-block;
    text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /*font-family: "Rubik", sans-serif;*/
    font-weight: 700;
    color: #111111;
    margin: 0px;
}
h1 {
    font-size: 45px;
}
h2 {
    /*font-size: 42px;*/
    font-size: 45px;
    font-weight: 900;
    margin: 20px 0 ;
    text-transform: uppercase;
}
h3 {
    font-size: 28px;
}
h4 {
    font-size: 22px;
}
h5 {
    font-size: 18px;
}
h6{
    /*font-size: 32px;*/
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

body {
    font-family: sans-serif;
    /*background-color: #F5F5F5;*/
}
ul {
    padding: 0;
}

li {
    list-style: none;
}

p{
    /*margin: 20px 0 ;*/
    color: rgb(148 148 148);
    /*color: #8F8D87;*/
    line-height: 25px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    /*color: #4C4C4C;*/
    margin: 0px;
}
ul, ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

button.trigger {
    border: none;
    margin: 15px;
    background-color: transparent;
}
img {
    /*border: solid 2px #000;*/
    /*border-radius: 8px;*/
    /*margin: 32px 0;*/
    /*cursor: pointer;*/
    transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
    -moz-transition: all ease-in 0.3s;
    -ms-transition: all ease-in 0.3s;
    -o-transition: all ease-in 0.3s;
    display: inherit;
    background-position:center;
    background-size: cover;
}
input,
textarea {
    width: 100%;
    border-radius: 10px;
    background: #fff;
    font-size: 16px;
    padding: 9px 20px;
    /*margin-bottom: 20px;*/
    color: #868AAA;

    -webkit-box-shadow: 0px 0px 51px rgba(180, 180, 180, 0.16);
    box-shadow: 0px 0px 51px rgba(180, 180, 180, 0.16);
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border: 1px solid rgba(84, 45, 237, 0.1);
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
    opacity: 1;
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
}

input:focus,
textarea:focus {
    border-color: #542DED;
}
*:focus {
    outline: none;
}
fieldset {
    position: relative;
    border: 0;
    padding: 0;
    font-size: 12px;
    /*font-family: "Alata", sans-serif;*/
}


@media only screen and (max-width: 414px) {
    .url,
    .sub-url {
        font-size: 14px;
    }

    .nav-sect {
        min-width: 59px;
        padding: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .avatars > li:not(:first-child) {
        margin-right: -30px;
    }
    .city_name{
        position: absolute;    top: 220px; right: 20px;
    }
}

@media screen and (min-width: 500px) {
    .item {
        width: 33%;
    }
    .text_intro {
        width: 400px
    }
    .text_intro2 {
        width: 600px
    }
}
@media screen and (min-width: 800px) {
    .item {
        width: 25%;
    }
}
@media screen and (min-width: 1200px) {
    .wrapper {
        /*margin-left: -30px;*/
    }
    .header_navbar {
        background:#fffef200 !important;
    }

    .city_name{
        position: absolute;    top: 220px; right: 20px;
    }
    .item {
        width: 22%;
    }
    .container {
        max-width: 1340px;
    }
    footer {
        margin: 0;
    }

    #btn-mobile {
        display: none;
    }
    #when {
        margin-top: -350px;
    }


    /*  footer.row(:first-child){
     margin: 100px 0;
  }*/
}

@media (max-width: 600px) {
    .carousel-inner{
        padding: 10px;
    }
    .carousel-indicators{
        bottom: -90px!important;
    }

    .menu_btn{
        width: 13%;
    }
    #home {
        padding: 190px 0 0px !important;
    }
    #menu {
        display: block!important;
        position: absolute;
        width: 100%;
        top: 0px !important;
        right: 0;
        background: #333;
        height: 0;
        transition: .6s;
        z-index: 10;
        visibility: hidden;
        overflow-y: hidden;
    }

    #nav.active #menu {
        height: 100vh;
        visibility: visible;
        overflow-y: auto;
        text-align: center;
        padding: 50px 15px;
        width: 100%;
        z-index: 20;
    }

    #menu a {
        padding: 1rem 0 !important;
        margin: 0 1rem !important;
        border-bottom: 2px solid rgba(0, 0, 0, .05);
    }

    #btn-mobile {
        display: flex;
    }

    #hamburguer {
        border-top: 2px solid;
        width: 20px !important;
    }

    #hamburguer::after, #hamburguer::before {
        content: '';
        display: block;
        width: 20px;
        height: 2px;
        background: currentColor;
        margin-top: 5px;
        transition: .3s;
        position: relative;
    }

    #nav.active #hamburguer {
        border-top-color: transparent;
    }

    #nav.active #hamburguer::before {
        transform: rotate(135deg);
    }

    #nav.active #hamburguer::after {
        transform: rotate(-135deg);
        top: -7px;
    }
}



@media only screen and (max-width: 320px) {
    .calendar-days div {
        width: 30px!important;
        height: 30px!important;
    }
    h2 {
        font-weight: 300;
    }
    .price_panel {
        margin: 22px !important;
    }
    .apply_btn{
        margin:5px 0px 5px 0px!important;
        width: auto;
        padding: 12px 14px 0 14px!important;
    }
    /*  #promo{
       width: 200px !important;
      }*/
    #faq {
        margin: 320px auto 0px;
    }
    .slider-wrapper {
        width: 100%!important;
    }
    .slide {
        width: 100%!important;
    }
    .hidden-web{
        display: block!important;
    }
    .has-feedback{
        width: 80%;
    }
    .icon-button{
        display: block !important;
    }
    .arrow_text {
        display: none;
    }
    .camp_video{
        width: 100% !important;
        height: 150px !important;
    }
    .camp_intro{
        height: 91vh !important;
    }
    .hero-area .hero-content h1 {
        line-height: 50px!important;
    }
    .graph{
        height: 300px !important;
    }
    .hidden {
        display: none !important;
    }
    .camp_card{
        margin-left:0px !important;
    }
    /*  .price_btn {
        display: none;
      }*/
    .main_title_camp{
        font-size: 44px !important;
    }
    .container{
        max-width: 320px !important;
        margin-bottom: 0px !important;
    }

    .intro{
        padding: 0 !important;
    }

    .page-scroll{
        color: white;
    }
    /*  .logos {
        height: 40px !important;
      }*/
    .flag{
        display: none !important;
    }
    .battery {
        display: none;
        margin: -200px 110px 0;
        height: 70px;
        border-radius: 15px;
    }

    .hero-area .hero-content p {
        margin-bottom: 20px;
    }
    .character{
        display: none;
    }
    .tags{
        display: none;
    }
    .nextcamp{
        padding: 0;
    }
    .call2action{
        border-radius: 0px !important;
    }
    .images{
        margin: 5px;
        width: 100%;
        border-radius: 20px;
        cursor: pointer !important;
    }
    .card--recipe {
        width:80vw !important;
    }
    .header-btn{
        padding: 15px;
    }
    .black_row{
        width: auto !important;
        margin: 50px auto;
    }
    .hero-area {
        padding: 220px 0 0px !important;
        margin: 0;
    }
    .story{
        margin:10px;
        /*padding: 10px;*/
        width: 100%;
        border-radius: 20px;
    }
    .title{
        position: absolute;
        left: 20px !important;
    }
    .tag {
        position: absolute;
    }
    .header0{
        display: none;
    }
    .main-btn {
        padding: 15px;
    }

    .camp_desc{
        overflow: hidden;
        height: 250px;
    }
    .col-hidden {
        display: none;
    }
    .calendar{
        margin: 35px 0;
        padding: 15px !important;
    }
    .btns {
        width: 283px;
    }
    .btn_test {
        margin: 50px auto 0 !important;
    }

}

/* Extra small devices */

@media only screen and (min-width: 330px) and (max-width: 600px) {
    .hero-area .hero-content h1 {
        line-height: 45px !important;
    }
    .price_panel {
        margin: 50px;
    }
    .has-feedback {
        width: 83% !important;
    }
    .apply_btn{
        margin:5px 0px 5px 0px!important;
        width: auto;
        padding: 12px 22px 0 22px!important;
    }
    #faq {
        margin: 220px auto 0px;
    }
    .about_text{
        margin-top: 0px!important;
    }
    .maintitle{
        font-size: 30px !important;
    }
    section {
        width: 100% !important;
    }
    .btns {
        width: 300px !important;
    }
    .icon-button{
        display: block !important;
    }
    .main_title_camp{
        font-size: 44px !important;
    }
    .camp_card{
        margin-left:0px !important;
    }
    .btn_test {
        margin: 50px auto 0 !important;
    }
    .sticky a {
        color: white!important;
    }


    /*    .slider {
    width: 100%;
}
.slides {
    transform: translateX(-450px);
}

.slider .card {
    opacity: 1;
}
.slider .card-focus {
    opacity: 1;
    transform: scale(1);
}*/
    .graph{
        height: 300px !important;
    }
    .arrow_text{
        display: none !important;
    }
    .camp_card{
        margin-left:0px !important;
    }
    .camp_video{
        width: 100%;
        height: 190px !important;
    }
    .camp_intro{
        height: 91vh !important;
    }
    /*    .has-feedback{
        width: 32vh !important;
      }*/
    .form-block{
        padding: 0 15px !important;
    }
    .container{
        max-width: 560px;
        margin-bottom: 0px !important;
    }
    .page-scroll{
        color: white;
    }
    .logos {
        /*height: 40px;*/
    }
    .flag{
        display: none !important;
    }
    .battery {
        display: none;
        margin: -200px 110px 0;
        height: 70px;
        border-radius: 15px;
    }
    .hidden{
        display: none !important;
    }
    .hidden-web{
        display: block !important;
    }
    .hero-area .hero-content p {
        margin-bottom: 50px !important;
    }
    .character{
        display: none;
    }
    .tags{
        display: none;
    }
    .nextcamp{
        padding: 0 !important;
    }
    /*  .camp_text{
        padding: 0px !important;
      }*/
    .call2action{
        border-radius: 0px !important;
    }
    .images{
        margin: 5px;
        width: 100%;
        border-radius: 20px;
        height: 200px;
    }
    /*  .card--recipe {
      width:80vw;
    }*/
    .header-btn{
        padding: 15px !important;
    }
    .black_row{
        width: auto !important;
        margin: 50px auto !important;
    }
    .hero-area.camp_intro {
        padding: 90px 40px 0px !important;
        margin: 0;
    }
    .story{
        margin:10px !important;
        /*padding: 10px;*/
        width: 100%;
        border-radius: 20px;
    }
    .title{
        position: absolute;
        left: 20px !important;
    }
    .tag {
        position: absolute;
    }
    .header0{
        display: none;
    }
    /*  .main-btn {
        padding: 15px !important;
      }*/

    .camp_desc{
        overflow: hidden;
        height: 250px !important;
    }
    .calendar{
        margin: 35px auto !important
    }
    .col-hidden {
        display: none;
    }
    .image_camp{
        height: 240px !important;

    }

    .slider-wrapper {
        width: 100% !important;
        height: 235px !important;
    }
    .slide {
        width: 100% !important;
    }
    .slide_where{
        width: 100% !important;
    }
    .modal-content{
        width: 100% !important;
    }
}


@media only screen and (min-width: 576px) and (max-width: 767px) {
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 40px;
    }
    .hero-area .hero-content .hero-btns a.demo-btn {
        margin-left: 20px;
    }
    .hero-area .hero-content h1 {
        font-size: 48px;
        line-height: 60px;
    }
    .header-btn {
        right: 60px;
    }
    .header-btn a i {
        display: inline-block;
    }
    .hero-area {
        padding: 220px 0 0px !important;
        margin: 0;
    }
}

@media (max-width: 767px) {
    h1 {
        font-size: 36px !important;
    }
    h2 {
        font-size: 36px !important;
    }
    .hero-area .hero-content .hero-btns a.demo-btn {
        margin-left: 0px !important;

    }
    .hero-area .hero-content .hero-btns a {
        width: 100% !important;
    }
    .hero-area {
        padding: 100px 0 0px !important;
        /*margin: 0 20px 30px;*/
        margin: 0 !important;
        /*background: linear-gradient(90.93deg, rgba(255, 228, 229, 0.7) 0.69%, rgba(84, 45, 237, 0.7) 99.34%);*/
    }
    .navbar-nav .nav-item:hover .sub-menu {
        top: 0;
    }
    .navbar-nav .nav-item .sub-menu {
        position: relative;
        width: 100%;
        top: 0;
        display: none;
        opacity: 1;
        visibility: visible;
    }
    .navbar-brand img {
        width: 150px;
    }
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 5px 12px;
    }
    .navbar-nav .nav-item {
        margin: 0;
    }
    .navbar-nav .nav-item a {
        display: block;
        padding: 4px 0;
        color: #111111;
    }
    .navbar-nav .sub-nav-toggler {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        color: #222;
        font-size: 18px !important;
        border: 0;
        width: 30px !important;
        height: 30px !important;
    }
    .hero-area .hero-right {
        display: none !important;
    }
    .hero-area .hero-content h1 {
        font-size: 36px !important;
        line-height: 45px;
    }
}


/* Medium devices */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    /*@media only screen and (max-width: 768px) {*/
    .container{
        max-width: 720px;
    }
    .page-scroll{
        font-size: 14px;
    }
    .battery {
        display: none;
    }
    .character{
        margin-left: -34px;
        margin-top: 0px;
    }
    .hero-area {
        margin: 0;
    }
    .social-sm {
        display: block;
    }
    .black-section img{
        width: 100%;
        padding: 20px;
    }
    .black_row {
        width: auto;
    }
    .images{
        width: 100%;
    }
    .nextcamp {
        margin: 0px;
    }
    .hero-area {
        padding: 250px 0 0px;
        margin: 0;
    }
    .card--recipe{
        width: 150px;
        height: 100px;
    }
    .hero-area .hero-right {
        display: none;
    }
    .navbar-nav .sub-nav-toggler {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        color: #222;
        font-size: 18px;
        border: 0;
        width: 30px;
        height: 30px;
    }
    .navbar-nav .nav-item .sub-menu {
        position: relative;
        width: 100%;
        top: 0;
        display: none;
        opacity: 1;
        visibility: visible;
    }
    .navbar-nav .nav-item:hover .sub-menu {
        top: 0;
    }
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 5px 12px;
    }
    .navbar-nav .nav-item {
        margin: 0;
    }
    .navbar-nav .nav-item a {
        display: block;
        padding: 4px 0;
        color: #111111;
    }
    .hero-area {
        padding: 150px 0 120px;
        /*background: linear-gradient(90.93deg, rgba(255, 228, 229, 0.7) 0.69%, rgba(84, 45, 237, 0.7) 99.34%);*/
    }
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-area {
        padding: 140px 0 120px;
    }
    .navbar-nav .nav-item {
        margin-right: 24px;
    }
    .control__content {
        color: white !important;
    }
}

/* Large devices */

@media (min-width: 1200px) {
    /*@media only screen and (max-width: 992px) {} */
    /*  .black-section{
        padding: 90px 200px;
      }*/
    .about_text{
        margin-right: 80px!important;
    }
    .apply_btn{
        margin:5px 0px 5px 40px!important;
        width: auto;
        padding: 12px 55px 0 55px!important;
    }
    .image1{
        margin: 0 100px;
    }
    .image2{
        margin: -30px 0 100px;
    }
    .image3{
        margin: 0 60px;
    }
    .image4{
        margin: -10px 50px;
    }
    #carouselExampleIndicators {
        margin: -75% 110%;
    }
    .has-feedback{
        width: 60%;
    }
    .has-subscribe{
        width: 80vh!important;
    }
    .control__content {
        color: white !important;
    }

    .carousel-indicators{
        bottom: -485px!important;
    }
    .menu_btn{
        width: auto;
        padding: 10px!important;
        /*border: 1px solid grey;*/
    }
    .carousel-inner{
        padding: 80px;
    }

    .image5{
        margin: 0 250px;
    }
    .image6{
        margin: 200px 0 -300px 0;
    }
    .image7{
        margin: 0 50px;
    }
    .image8{
        margin: 20px 100px;
    }
    .image9{
        margin: 20px 0px;
    }
    .image10{
        margin: -50px 0 50px;
    }
    .image11{
        margin: 120px 0px -120px;
    }
    .image12{
        margin: 180px 90px 0;
    }
    .image13{
        margin: 60px 0 -30px 0;
    }
    .image14{
        margin:-10% 0%;
    }
    .image15{
        margin:20% 0%;
    }
    .image16{
        margin:20% 0%;
    }
    .image17{
        margin:-10% 0%;
    }
    .image18{
        margin: -7% 6%;
    }
    .image19{
        margin:0px;
    }
    .image20{
        margin:-4% 34%;
    }
    .image21{
        margin: 30px 50px;
    }
    .image22{
        margin: 32% 33%;
    }
    .image23{
        margin: -12% -10% 35px;
    }
    .image26 {
        margin:0% 10%;
    }
    .image27 {
        margin:35% 0%;
    }
    .image28 {
        margin:20% 0%;
    }
    .image29 {
        margin:-200% 140%;
        width: auto;
        height: auto;
        max-width: 300%;
        max-height: 100%;
    }
    .btn_test {
        margin: 50px auto 0 !important;
    }
}

/*============================
label
============================= */

.control {
    display: inline-flex;
    position: relative;
    margin: 5px;
    cursor: pointer;
    border-radius: 99em;
}

.control input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.control svg {
    margin-right: 6px;
    border-radius: 50%;
    -webkit-box-shadow: 3px 3px 16px rgba(0, 0, 0, .2);
    box-shadow: 3px 3px 16px rgba(0, 0, 0, .2)
}

.control__content {
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 32px;
    color: #333;
    background-color: rgba(0, 0, 0, .05);
    border-radius: 99em;
}
.control__content2 {
    display: inline-flex;
    align-items: center;
    padding: 6px 8px;
    font-size: 10px;
    line-height: 20px;
    color: rgb(255 255 255);
    background-color: rgba(0, 0, 0, .05);
    border-radius: 99em;
}

.control:hover .control__content {
    background-color: rgba(0, 0, 0, .1);
}

.control input:focus ~ .control__content {
    -webkit-box-shadow: 0 0 0 .25rem rgba(12, 242, 143, .2);
    box-shadow: 0 0 0 .25rem rgba(12, 242, 143, .2);
    background-color: rgba(0, 0, 0, .1);
}

.control input:checked ~ .control__content {
    background-color: rgba(12, 242, 143, .2);
}

/*============================
header
============================= */

.header_navbar {
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.sticky {
    position: fixed;
    z-index: 10;
    /*background-color: #fff;*/
    background: #fffef2 !important;
    -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}



.sticky a{
    color: black;
}
.sticky .control__content{
    color: black !important;
}

.sticky .translate{
    border: 1px solid #333 !important;
}

.sticky .translate i{
    color: #333 !important;
}

.sticky .navbar {
    padding: 15px 0;
}

.navbar {
    padding: 25px 0;
    border-radius: 5px;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.navbar-brand {
    padding: 0;
}

.navbar-brand img {
    width: 230px;
}



.navbar-toggler {
    padding: 0;
}

.navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.navbar-toggler .toggler-icon {
    width: 30px;
    height: 2px;
    background-color: #4C4C4C;
    display: block;
    margin: 5px 0;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.navbar-toggler.active .toggler-icon:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 7px;
}

.navbar-toggler.active .toggler-icon:nth-of-type(2) {
    opacity: 0;
}

.navbar-toggler.active .toggler-icon:nth-of-type(3) {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    top: -7px;
}



.navbar-nav .nav-item {
    position: relative;
    margin-right: 40px;
}


.navbar-nav .nav-item a {
    /*font-weight: 500;*/
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.08em;
    /*text-transform: uppercase;*/
    color: #8d8d8d;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}



.navbar-nav .nav-item a.active, .navbar-nav .nav-item a:hover {
    color: #542DED;
}

.navbar-nav .nav-item:hover .sub-menu {
    top: 100%;
    opacity: 1;
    visibility: visible;
}



.navbar-nav .nav-item .sub-menu {
    width: 200px;
    background-color: #4C4C4C;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 110%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.sticky .navbar-toggler .toggler-icon {
    background-color: #111111;
}

.sticky .navbar-nav .nav-item a {
    color: #111111;
}

.sticky .navbar-nav .nav-item a::before {
    background-color: #542DED;
}

.sticky .navbar-nav .nav-item a.active, .sticky .navbar-nav .nav-item a:hover {
    color: #542DED;
}

.navbar-nav .nav-item .sub-menu li {
    display: block;
}

.navbar-nav .nav-item .sub-menu li a {
    display: block;
    padding: 8px 20px;
    color: #4C4C4C;
}

.navbar-nav .nav-item .sub-menu li a.active, .navbar-nav .nav-item .sub-menu li a:hover {
    padding-left: 25px;
    color: #542DED;
}

.navbar-nav .sub-nav-toggler {
    display: none;
}


.header_navbar {
    -webkit-box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px !important;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px !important;
}

.navbar{
    padding: 0;
}

.nav-item {
    line-height: 80px;
}


.navbar-nav .sub-nav-toggler span {
    width: 8px;
    height: 8px;
    border-left: 1px solid #222;
    border-bottom: 1px solid #222;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    top: -5px;
}

#header {
    box-sizing: border-box;
    height: 70px;
    padding: 1rem;
    display: flex;
    align-items: center;
    z-index: 4;
    justify-content: space-between;
    /*background: #e7e7e7e7;*/
}

#menu {
    display: flex;
    list-style: none;
    gap: .5rem
}

#menu a {
    display: block;
    padding: .5rem;
    line-height: 20px;
}

#btn-mobile {
    padding: .5rem 1rem !important;
    font-size: 1rem;
    border: none;
    background: none;
    cursor: pointer;
    gap: .5rem;
}

/*============================
progress camp
============================= */

.streak-container {
    position: relative;
}
.streak-container progress {
    display: block;
    width: 100%;
    height: 6px;
    appearance: none;
    border: none;
}
.streak-container progress::-webkit-progress-bar {
    background-color: #eae9ea;
    border-radius: 6px;
}
.streak-container progress::-webkit-progress-value {
    background: linear-gradient(90deg, #014f77 0%, #5daed0 100%);
    border-radius: 6px;
}
.streak-container .grid-lines {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.streak-container .grid-lines i {
    background-color: #FFF;
    width: 5px;
    height: 20px;
}
.streak-container .grid-lines i:first-child, .streak-container .grid-lines i:last-child {
    background-color: transparent;
    width: 0;
}

.example-container {
    padding: 1px 1rem 1rem 1rem;
    width: 100%;
    max-width: 700px;
    background-color: #FFF;
}

/*============================
hero-area
============================= */

.hero-area {
    padding: 220px 0 10px;
    margin: 0 80px 30px;
    position: relative;
    /*  overflow: hidden;
      z-index: 1;*/
}
.hero-area .hero-content span {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 12px;
    color: #4C4C4C;
}
.hero-area .hero-content h1 {
    /*  font-weight: 500;
      font-size: 50px;
      line-height: 60px;*/
    margin-bottom: 20px;
    color: #111111;
    visibility: visible;
    animation-delay: 0.4s;
    animation-name: fadeInUp;
    text-align: left;
    font-size: 76px;
    line-height: 87px;
}
.hero-area .hero-content p {
    color: #4C4C4C;
    margin-bottom: 70px;
    padding-right: 0px;
}
.hero-area .hero-content .hero-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.hero-area .hero-content .hero-btns a.demo-btn {
    margin-left: 30px;
    background: none;
    color: #111111;
}
.hero-area .hero-content .hero-btns a.demo-btn:hover {
    color: #542DED;
}
.hero-area .hero-right {
    position: absolute;
    right: 0;
    z-index: -1;
}

/*=====================================
feature
======================================= */
.feature-area {
    position: relative;
    z-index: 1;
    /*padding: 15px;*/
    margin-top: 20px;
}

.feature-area .single-feature {
    padding: 35px 30px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    overflow: hidden;
    text-align: center;
    margin-bottom: 30px;
}

.feature-area .single-feature:hover {
    -webkit-box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.32);
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.32);
}

.feature-area .single-feature .feature-icon {
    margin-bottom: 15px;
}

.feature-area .single-feature .feature-icon i {
    font-size: 56px;
    line-height: 56px;
    color: #542DED;
}

.feature-area .single-feature .feature-content h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
}

.feature-area .single-feature .feature-content p {
    color: #868AAA;
}

/*.feature-area:hover,.feature-area:focus {
  transform: translateY(-4px);
  border-radius: 20px;
  -webkit-box-shadow: 0 0 0 2px #dadada, 0 22px 43px rgb(0 0 0 / 15%);
  box-shadow: 0 0 0 2px #dadada, 0 22px 43px rgb(0 0 0 / 15%);
}*/
/*=====================================
footer
======================================= */

.footer-area {
    background: #001AD8;
    padding: 120px 0 90px;
}

.right svg:hover {
    fill: #b2b2ac;
}

.left svg:hover {
    fill: #b2b2ac;
}

.footer-area h6 {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.74);
    margin-bottom: 5px;
}

.footer-area .social-link {
    margin-bottom: 30px;
}

.footer-area .social-link ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.footer-area .social-link ul li a {
    font-size: 18px;
    color: #fff;
    display: block;
    padding: 8px;
}

.footer-area .subscribe-form-wrapper {
    margin-bottom: 30px;
}

.footer-area .subscribe-form-wrapper h6 {
    margin-bottom: 20px;
}

.footer-area .subscribe-form-wrapper .subscribe-form {
    max-width: 350px;
    margin: auto;
}

.footer-area .subscribe-form-wrapper .subscribe-form .email-input {
    position: relative;
}

.footer-area .subscribe-form-wrapper .subscribe-form .email-input label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.74);
    margin-bottom: 5px;
}

.footer-area .subscribe-form-wrapper .subscribe-form .email-input input {
    background: transparent;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.74);
    color: #fff;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0;
    padding: 0 45px 10px 50px;
}

.footer-area .subscribe-form-wrapper .subscribe-form .email-input input::-webkit-input-placeholder {
    color: #fff;
    opacity: 1;
}

.footer-area .subscribe-form-wrapper .subscribe-form .email-input input:-ms-input-placeholder {
    color: #fff;
    opacity: 1;
}

.footer-area .subscribe-form-wrapper .subscribe-form .email-input input::-ms-input-placeholder {
    color: #fff;
    opacity: 1;
}

.footer-area .subscribe-form-wrapper .subscribe-form .email-input input::placeholder {
    color: #fff;
    opacity: 1;
}

.footer-area .subscribe-form-wrapper .subscribe-form .email-input span.icon {
    position: absolute;
    left: 10px;
    bottom: 10px;
    color: rgba(255, 255, 255, 0.74);
}

.footer-area .subscribe-form-wrapper .subscribe-form .email-input button {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 44px;
    height: 44px;
    border-color: transparent;
    color: #542DED;
}

.footer-area .footer-credit {
    padding: 30px 0;
}

.footer-area .footer-credit p {
    color: #fff;
}

.footer-area .footer-credit p a {
    color: inherit;
}

/*=====================================
back-to-top
======================================= */


.back-to-top {
    width: 45px;
    height: 45px;
    background: #542DED;
    text-align: center;
    line-height: 45px;
    font-size: 20px;
    color: #fff;
    border-radius: 5px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.back-to-top.btn-hover {
    position: fixed;
    z-index: 9;
}

.back-to-top:hover {
    color: #fff;
}


/*=====================================
footer
======================================= */

.footer {
    text-align: center;
    font-weight: 500;
    color: #999;
    font-size: 12pt;
    margin: 50px;
}

.footer span {
    color: rgba(0, 0, 0, 0.25);
}
.footer b {
    color: rgba(0, 0, 0, 0.5);
    margin-left: 10px;
}
.footer a {
    color: rgba(0, 0, 0, 0.25);
    border-bottom: 1px dotted;
}
.footer a:hover {
    border-bottom: 0;
    text-decoration: none;
}

/*footer.container {
  padding: 0 35px;
}*/

footer a {
    color: #6c757d!important;
}

footer li {
    margin-top: 16px !important;
}


/*=====================================
video card
======================================= */

.card--recipe {
    position: relative;
    width: 300px;
    height: 200px;
    border-radius: 12px;
    -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.04), 0 7px 18px 0 rgba(0, 0, 0, 0.05), 0 12px 24px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.04), 0 7px 18px 0 rgba(0, 0, 0, 0.05), 0 12px 24px 0 rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.25s ease;
    cursor: pointer;
}
.gallery .card--recipe {
    width: 100%;
    height: 240px;
}
.card--recipe__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    -o-object-fit: cover;
    object-fit: cover;
}
.card--recipe__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 1;
    overflow: hidden;
}
.card--recipe__video video {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: all 0.2s ease;
    opacity: 0;
}
.card--recipe::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    top: 50%;
    right: 0;
    background: black;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 100%);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    z-index: 2;
    content: "";
}
.card--recipe__play {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(50% - 23px);
    top: calc(52% - 25px);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: white;
    z-index: 3;
}
.card--recipe__play img {
    width: 30%;
}
.card--recipe__content {
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    padding: 26px 26px 34px 26px;
    width: 100%;
    z-index: 3;
}
.card--recipe__title {
    color: white;
    font-size: 27px;
    margin: 0;
    font-weight: 900;
    margin-bottom: 8px;
}
.card--recipe__labels {
    margin: 0;
    padding: 0;
    list-style: none;
}
.card--recipe__label-category, .card--recipe__label {
    border-radius: 3px;
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    padding: 4px 8px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
}
.card--recipe__label-category {
    background: #2BB778;
}
.card--recipe.is--playing video {
    opacity: 1;
}
.card--recipe[data-tilt] {
    transform-style: preserve-3d;
}
.card--recipe[data-tilt] .card--recipe__play {
    transform: translateZ(20px);
}
.card--recipe[data-tilt] .card--recipe__content {
    transform: translateZ(20px);
}
.card--recipe[data-tilt]:hover {
    -webkit-box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.08), 0 8px 18px 0 rgba(0, 0, 0, 0.08), 0 19px 28px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.08), 0 8px 18px 0 rgba(0, 0, 0, 0.08), 0 19px 28px 0 rgba(0, 0, 0, 0.1);
}
.indigo2 {
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 1px 1px 6px rgb(0 0 0 / 50%);
}
.circle {
    border: 1px solid white;
    border-radius: 9px;
    background-color: transparent;
}

/*=====================================
avatars
======================================= */


.avatars {
    display: inline-flex;
    flex-direction: row-reverse;
}
.avatars > li {
    width: 60px;
    height: 60px;
}
.avatars > li img {
    max-width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid white;
    /*border-right: 1px solid white;*/
}
.avatars > li:not(:first-child) {
    margin-right: -20px;
    transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.avatars:hover li:not(:first-child) {
    transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
    margin-right: 10px;
}

/*=====================================
faq
======================================= */


.accordion .accordion-item {
    border-bottom: 1px solid #e5e5e5;
}
.accordion .accordion-item button[aria-expanded=true] {
    border-bottom: 1px solid #03b5d2;
}
.accordion button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 6px;
    color: #7288a2;
    font-size: 1.15rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
}
.accordion button:hover, .accordion button:focus {
    cursor: pointer;
    color: #03b5d2;
}
.accordion button:hover::after, .accordion button:focus::after {
    cursor: pointer;
    color: #03b5d2;
    border: 1px solid #03b5d2;
}
.accordion button .accordion-title {
    padding: 0;
}
.accordion button .icon {
    display: inline-block;
    position: absolute;
    top: 8px;
    right: 0;
    width: 22px;
    height: 22px;
    border: 1px solid;
    border-radius: 22px;
}
.accordion button .icon::before {
    display: block;
    position: absolute;
    content: "";
    top: 9px;
    left: 5px;
    width: 10px;
    height: 2px;
    background: currentColor;
}
.accordion button .icon::after {
    display: block;
    position: absolute;
    content: "";
    top: 5px;
    left: 9px;
    width: 2px;
    height: 10px;
    background: currentColor;
}
.accordion button[aria-expanded=true] {
    color: #03b5d2;
}
.accordion button[aria-expanded=true] .icon::after {
    width: 0;
}
.accordion button[aria-expanded=true] + .accordion-content {
    opacity: 1;
    max-height: 20em;
    transition: all 200ms linear;
    will-change: opacity, max-height;
}
.accordion .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
}
.accordion .accordion-content p {
    font-size: 1rem;
    font-weight: 300;
    margin: 2em 0;
}

.faq-area .faq-accordion .single-accordion {
    margin-bottom: 15px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.faq-area .faq-accordion .single-accordion .accordion-btn button {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #111111;
    background: #fff;
    border: none;
    padding: 15px;
    padding-right: 25px;
    border-radius: 8px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.faq-area .faq-accordion .single-accordion .accordion-btn button::after {
    content: "\ea5e";
    position: absolute;
    right: 15px;
    top: 18px;
    font: normal normal normal 1em/1 "LineIcons";
    color: #542DED;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.faq-area .faq-accordion .single-accordion .accordion-btn button.collapsed::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.faq-area .faq-accordion .single-accordion .accordion-btn button i {
    color: #1f1b1d;
    position: relative;
    margin-right: 15px;
    z-index: 1;
}

.faq-area .faq-accordion .single-accordion .accordion-btn button i::after {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
    top: 0;
    z-index: -1;
    background: #f2f1e6;
    border-radius: 120px 8px 88px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.faq-area .faq-accordion .single-accordion .accordion-content {
    padding-right: 40px;
    padding-left: 50px;
    padding-bottom: 15px;
}




/*=====================================
poll
======================================= */

#pollContainer {
    max-width: 400px;
    margin: 0;
    padding: 10px 5px;
    border-radius: 3px;
    position: relative;
}

#pollButtons ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

#pollButtons li {
    border: 1px solid #333;
    margin: 5px auto;
    padding: 10px;
    color: #333;
    width: 45%;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.4s ease;
}

/*=====================================
cookie
======================================= */

.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2147483645;
    box-sizing: border-box;
    width: 100%;

    background-color: #F1F6F4;
}

.cookie-consent-banner__inner {
    max-width: 1160px;
    margin: 0 auto;
    padding: 15px 0;
}

.cookie-consent-banner__copy {
    margin-bottom: 10px;
}

.cookie-consent-banner__actions {
}

.cookie-consent-banner__header {
    margin-bottom: 8px;
    /*font-family: "CeraPRO-Bold", sans-serif, arial;*/
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.cookie-consent-banner__description {
    /*font-family: "CeraPRO-Regular", sans-serif, arial;*/
    font-weight: normal;
    color: #838F93;
    font-size: 14px;
    line-height: 16px;
}

.cookie-consent-banner__cta {
    box-sizing: border-box;
    display: inline-block;
    /*min-width: 164px;*/
    padding: 10px;
    border-radius: 2px;
    background-color: #2CE080;
    color: #FFF;
    text-decoration: none;
    text-align: center;
    /*font-family: "CeraPRO-Regular", sans-serif, arial;*/
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
}

.cookie-consent-banner__cta--secondary {
    padding: 9px 13px;
    border: 2px solid #3A4649;
    background-color: transparent;
    color: #2CE080;
}

.cookie-consent-banner__cta:hover {
    background-color: #20BA68;
}

.cookie-consent-banner__cta--secondary:hover {
    border-color: #838F93;
    background-color: transparent;
    color: #22C870;
}

.cookie-consent-banner__cta:last-child {
    margin-left: 16px;
}


/*=====================================
slider
======================================= */

.slider-wrapper {
    width: 80%;
    height: 50%;
    margin: 30px auto;
    text-align: center;
    position: relative;

}
.slider-wrapper2 {
    /*height: auto;*/
    margin: 0px;
    width: 100%;
    height: 62%;
}
.slider {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 20px;
    margin-bottom: 20px;
    scroll-behavior: smooth;
}
.slider::-webkit-scrollbar {
    display: none;
}

.slider2 {
    height: auto;
    border-radius: 0px;
}

.slide {
    width: 25%;
    height: 80%;
    margin-right: 25px;
    flex-shrink: 0;
    /*padding-top: 40px;
    /*background: steelblue;*/
    color: goldenrod;
    font-weight: bold;
    font-size: 1.3rem;
}

.slide:last-of-type {
    margin-right: 0;
}

.slide2 {
    width: 100% !important;
}





/*=====================================
wifi animation
======================================= */

.wifi-symbol {
    display: none;
}
.wifi-symbol [foo], .wifi-symbol {
    display: block;
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 150px;
    height: 150px;
    margin-top: -187.5px;
    -ms-transform: rotate(-45deg) translate(-100px);
    -moz-transform: rotate(-45deg) translate(-100px);
    -o-transform: rotate(-45deg) translate(-100px);
    -webkit-transform: rotate(-45deg) translate(-100px);
    transform: rotate(-45deg) translate(-100px);
}
.wifi-symbol .wifi-circle {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 21.4285714286px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-color: #0ba965;
    border-style: solid;
    border-width: 1em 1em 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 0 100% 0 0;
    opacity: 0;
    -o-animation: wifianimation 3s infinite;
    -moz-animation: wifianimation 3s infinite;
    -webkit-animation: wifianimation 3s infinite;
    animation: wifianimation 3s infinite;
}
.wifi-symbol .wifi-circle.first {
    -o-animation-delay: 800ms;
    -moz-animation-delay: 800ms;
    -webkit-animation-delay: 800ms;
    animation-delay: 800ms;
}
.wifi-symbol .wifi-circle.second {
    width: 5em;
    height: 5em;
    -o-animation-delay: 400ms;
    -moz-animation-delay: 400ms;
    -webkit-animation-delay: 400ms;
    animation-delay: 400ms;
}
.wifi-symbol .wifi-circle.third {
    width: 3em;
    height: 3em;
}
.wifi-symbol .wifi-circle.fourth {
    width: 1em;
    height: 1em;
    opacity: 1;
    background-color: #FFFFCC;
    -o-animation: none;
    -moz-animation: none;
    -webkit-animation: none;
    animation: none;
}

@-o-keyframes wifianimation {
    0% {
        opacity: 0.4;
    }
    5% {
        opactiy: 1;
    }
    6% {
        opactiy: 0.1;
    }
    100% {
        opactiy: 0.1;
    }
}
@-moz-keyframes wifianimation {
    0% {
        opacity: 0.4;
    }
    5% {
        opactiy: 1;
    }
    6% {
        opactiy: 0.1;
    }
    100% {
        opactiy: 0.1;
    }
}
@-webkit-keyframes wifianimation {
    0% {
        opacity: 0.4;
    }
    5% {
        opactiy: 1;
    }
    6% {
        opactiy: 0.1;
    }
    100% {
        opactiy: 0.1;
    }
}


/*=====================================
Modal
======================================= */

.btn.close{
    color: red;
}
.modal{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    z-index: 100;
    display:none;
    background-color: rgba(0,0,0,0.4);

}
.modal .modal-wrapper{
    min-width: 300px;
    min-height: 200px;
    max-width: 450px;
    max-height: 600px;
    border-radius: 2px;
    background-color: #fff;
    color: #222;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    margin: 0 auto;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
}
.modal .modal-header{
    width: 100%;
    background-color: rgba(0, 0, 0, .05);
    padding: 15px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 22px;
}
.modal .modal-inner,
.modal .modal-footer{
    padding: 10px 15px;
}
.modal-footer{
    text-align: right;
}
.modal-footer .btn:last-child{
    margin-right: 0;
}



/*=====================================
animated button
======================================= */

.button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}
.button.learn-more {
    width: 12rem;
    height: auto;
}
.button.learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #282936;
    border-radius: 1.625rem;
}
.button.learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
}
.button.learn-more .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 1.125rem;
    width: 0.5625rem;
    height: 0.125rem;
    background: none;
}
.button.learn-more .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.25rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
}
.button.learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 3.85rem;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: left;
    text-transform: uppercase;
}

.button:hover .circle {
    width: 100%;
}
.button:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
}
.button:hover .button-text {
    color: #fff;
}


/*=====================================
table price - howitworks page
======================================= */


.styled-table {
    border-collapse: collapse;
    margin: 25px;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 100%;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    /*box-shadow: 0px 0px 10px rgba(114, 114, 113, 0.5);*/
    border-radius: 15px;
    overflow:hidden
}

.styled-table thead tr {
    background-color: #c4c4bf;
    color: #ffffff;
    text-align: left;
}
.styled-table th,
.styled-table td {
    padding: 12px 15px;
}
.styled-table tbody tr {
    /*border-bottom: 1px solid #dddddd;*/
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    /*border-bottom: 2px solid #009879;*/
}
.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #c4c4bf;
}

/*=====================================
blog
======================================= */


.site__wrapper {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

.site__info {
    clear: both;
    display: block;
    font-variant: small-caps;
    letter-spacing: 2px;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    text-align: center;
}

.grid {
    float: none;
    width: 100%;
    padding-left: 0.2em;
    padding-right: 0.2em;
    padding-bottom: 0.4em;
}
@media screen and (min-width: 40.063em) {
    .grid {
        float: left;
        width: 33.333%;
    }
}

.card__image {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: block;
    min-height: 240px;
    overflow: hidden;
    position: relative;
}
.card__image > img {
    display: block;
    margin-bottom: 0;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}
.card__image:hover > img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.card__title {
    color: white;
    display: inline-block;
    /*font-family: "Montserrat";*/
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.75em;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.card__title:hover {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
}

.card__overlay {
    content: "";
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
}

.card__overlay--indigo {
    background-image: linear-gradient(to bottom, rgba(92, 107, 192, 0.1), rgba(92, 107, 192, 0.8));
    z-index: 2;
}

.card__overlay--blue {
    background-image: linear-gradient(to bottom, rgba(41, 182, 246, 0.1), rgba(41, 182, 246, 0.8));
    z-index: 2;
}

.card__overlay-content {
    position: absolute;
    bottom: 0;
    padding: 1.5em;
    z-index: 3;
}

.card__meta {
    margin: 0;
    margin-right: -1em;
    margin-bottom: 0.75em;
}
.card__meta.card__meta--last {
    margin-bottom: 0;
}
.card__meta li {
    color: #f8f8f8;
    display: inline;
    list-style-type: none;
    margin-bottom: 0;
    padding-right: 1em;
    font-size: 0.85em;
    font-variant: small-caps;
    letter-spacing: 1px;
}
.card__meta li:not(:last-child):after {
    content: "●";
    font-style: normal;
    position: relative;
    color: rgba(255, 255, 255, 0.25);
    right: -0.6em;
}
.card__meta li > a {
    color: #f8f8f8;
}




/*=====================================
share button
======================================= */

#app nav {
    /*position: relative;*/
    /*  width: 100%;
      height: 50px;*/
}
#app nav .action .btn.share {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #f56263;
    outline: none;
    border: none;
    cursor: pointer;
    z-index: 2;
}
#app nav .action .btn.share::before, #app nav .action .btn.share::after {
    position: absolute;
    content: "";
    width: 80%;
    height: 80%;
    background: rgba(252, 21, 22, 0.35);
    top: 5px;
    left: 5px;
    border-radius: 50%;
    transform: scale(2);
    opacity: 0;
    transition: all 200ms cubic-bezier(0.32, 0.4, 0.55, 1.27);
    pointer-events: none;
}
#app nav .action .btn.share::after {
    transition: all 450ms cubic-bezier(0.32, 0.4, 0.55, 1.27);
}
#app nav .action .btn.share:active::before, #app nav .action .btn.share:active::after {
    transform: scale(0.95);
    opacity: 0.85;
}
#app nav .action .actions {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 50px;
    width: 50px;
    background: #fc6666;
    border-radius: 50px;
    z-index: 1;
    transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.175);
    -webkit-box-shadow: 0 2px 6px rgba(252, 21, 22, 0.45);
    box-shadow: 0 2px 6px rgba(252, 21, 22, 0.45);
    overflow: hidden;
    pointer-events: none;
}
#app nav .action .actions.active {
    width: 140px;
}
#app nav .action .actions.active::before, #app nav .action .actions.active::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    top: 10px;
    animation: scale 200ms both;
    cursor: pointer;
}
#app nav .action .actions.active::before {
    right: 50px;
}
#app nav .action .actions.active::after {
    right: 95px;
}

.share_icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: #fff;
    width: 20px;
    height: 20px;
    transition: transform 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 4;
    pointer-events: none;
}
.share_icon.close {
    opacity: 0;
}

.btn.share.active .upload.share_icon {
    opacity: 0;
}

.btn.share.active .close.share_icon {
    opacity: 1;
}

.menu_btn{
    background-color: transparent;
    color: rgb(148 148 148);
    margin:5px;
    padding: 12px 3px 0 3px;
    text-align: center;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 10px;
}

button:active .share_icon {
    transform: scale(0.8) translate(-58%, -58%);
}

@keyframes scale {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
.support {
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 10px;
    display: flex;
}




.images:hover {
    opacity: 0.5 !important;
}


/* calendar */

.calendar {
    background-color: #fff;
    width: 280px;
    height: 300px;
    display: block;
    padding: 18px;
    border-radius: 12px;
    box-shadow: 0 32px 32px -8px rgba(0, 0, 0, 0.08), 0 0 32px -8px rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2);
}

.calendar header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.calendar header svg {
    cursor: pointer;
}

.calendar .days {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.calendar .days span {
    width: 32px;
    height: 32px;
    display: grid;
    font-weight: 600;
    place-items: center;
    color: rgba(0, 0, 0, 0.25);
}

.calendar .dates .flex {
    display: flex;
    margin-top: 4px;
    justify-content: space-between;
}

.calendar .dates span {
    width: 32px;
    height: 32px;
    display: grid;
    cursor: pointer;
    border-radius: 6px;
    place-items: center;
}

.calendar .dates span:hover {
    background-color: #e6e6e6;
}

.calendar .dates span.active {
    color: white;
    font-weight: 600;
    background-color: black;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}

.calendar .dates span.start {
    background-color: green;
}

.previous, .next {
    color: rgba(0, 0, 0, 0.25);
}


.qty_slides{
    position: absolute;
    bottom: 10px;
    right: 25px;
    background-color: #000000b8;
    color: white;
    border-radius: 5px;
    font-size: 10px;
    padding: 3px 8px;
}



.multi-button {
    border: 1px solid #686868;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    padding: 0px;
}
.multi-button button {
    background-color: white;
    border: none;
    margin: 0;
    padding: 1em 2em;
    font-weight: bold;
    outline: none;
    font-size: 10px;
    width: 100%;
    text-align: left;
}
.multi-button button:hover {
    cursor: pointer;
    background-color: #00848a30;
}
.multi-button .active {
    background-color: #00848a30;
    color: white;
}

.multi-button :first-child {
    border-radius: 15px 0 0 0px;
}

.multi-button :last-child {
    border-radius: 0 15px 0px 0;
}

.dropdown {
    width: 100%;
    padding: 0px;
    border: 1px solid #686868;
    border-top: none;
    border-radius: 0px 0px 15px 15px ;
}
.dropdown > * {
    width: 100%;
}

#dropdownMenu1{
    border-radius: 0px 0px 15px 15px ;
    background-color: white;
    border: none;
    margin: 0;
    text-align: left;
    padding: 1em 2em;
    font-weight: bold;
    outline: none;
    font-size: 10px;
    width: 100%;
}



/* the grid */
.grid {
    width: 100%;
    max-width: 770px;
    margin: -20% auto 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 200px 200px 200px auto;
    grid-gap: 2em;
}

/* panels */
.panel {
    color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    box-shadow: 0 0 0 2px #222;
    border-radius: 15px;
}

/* individual panels */
.panel-title {
    box-shadow: none;
    grid-column: span 3;
}
.panel-1 {
    box-shadow: none;
    background-size: contain;
}
.panel-2 {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/cat-001.svg);
}
.panel-3 {
    box-shadow: none;
    align-self:center;
}
/*.panel-4 {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/cat-003.svg);
}
.panel-5 {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/cat-004.svg);
}
.panel-6 {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/cat-005.svg);
}*/
.panel-7 {
    height:50px;
    background:white;
    transform:rotate(-5deg);
    grid-row:4;
    padding: 15px;
    grid-column:1;
    margin-top:-10px;
    margin-left:-10px;
}
.panel-8 {
    /*background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/cat-007.svg);*/
    grid-column:1/3;
    grid-row:4;
    background-size:100%;
    background-position:right;
}
.panel-9 {
    /*background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/cat-008.svg);*/
    box-shadow: none;
    background-size: contain;
}
.panel-copyright {
    box-shadow: none;
    font-size: .75em;
    justify-self:end;
}






.reviews {
    width : 600px;
    height : 250px;
    overflow : hidden ;
    border-radius : 5px;
    margin : 50px auto ;
    position : relative ;
}
.panel_review {
    width : 100%;
    max-height: 100%;
    position : absolute ;
}
.PanelAct {
    left : 0 ;
}
.panel_review img {
    width : 100px;
    height : 100px;
    text-align : center ;
    float : left ;
    margin : 25px;
}
/*== Removal Start
png images background
==*/

/*== Removal End==*/

.panel_review p {
    float : left ;
    width : 420px;
    clear : right ;
    text-align : justify ;
    margin : 25px 0 10px 0;
    font-size : 18px ;
    color : #aaa ;
    line-height : 24px
}
.panel_review h7 {
    float : left ;
    width: 200px;
    font-size : 14px ;
    color : #999 ;
    font-weight : bold ;
    font-style : italic ;
}
.control_review {
    position : absolute ;
    bottom : 20px;
    right : 30px;
}
.control_review span {
    background-color : rgba(0,0,0,0.1) ;
    padding : 5px;
    display : inline-block ;
    vertical-align : top ;
    margin-left : 5px ;
    border-radius : 50%;
    cursor : pointer ;
    transition : 0.3s ease-in
}
.ContActive {
    background-color : #bbb !important ;
}





.wrap {
    /*background: green;*/
    /*width: 500px;*/
    position: relative;
    height: 450px;
    overflow-x: visible;
    overflow-y: hidden;
    /*max-height: 50px;*/
}
.inner_wrraper {
    position: relative;
    height:400px;
    /*width will depend on the total with of the sectioin blocks to contain*/
    width: 2620px;
    margin: -6px;
    /*max-height: 50px;*/
    /*background: pink;*/

}

.each_camp {
    position: relative;
    font-size: 12px;
    color: black;
    /*background: yellow;*/
    width: 290px;
    height: 400px;
    float: left;
    opacity: .9;
}

::-webkit-scrollbar{
    height: 12px;
    width: 12px;
    padding: 0 20px;
}
::-webkit-scrollbar-thumb:horizontal{
    background: #00848a30;
    border-radius: 12px;
    width: 150px;
}



/* dissapear text */

/*.disappear:focus {
    width: 100%;
    height: 75px;
    position: absolute;
    content: " ";
    bottom: 0;
    background: linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.71) 39%,#fff);
}*/








/*-----------------------------------------------------------------------------------

    Name: Landing Page for eCommerce HTML
    Author: UIdeck;
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/
/*===========================
    01.COMMON css
===========================*/

#bottom_menu .price_btn.active, #bottom_menu .price_btn:hover
{
    background-color: #00848a30 !important;
    color: #00848A!important;
}



.bg_cover {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.success {
    color: #7CB637;
}

.error {
    color: #FF4B22;
}



/*===== All Button Style =====*/
.main-btn {
    /*      line-height: 1.5rem;
          font-size: 1rem;*/
    font-size: 14px;
    line-height: 20px;
    /*font-weight: 500;*/
    letter-spacing: -0.025em;
    border-radius: 0.75rem;

    display: inline-block;
    /*font-weight: 600;*/
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 12px 24px;
    /*padding:10px 15px;*/
    /*font-weight: bold;*/
    /*font-size: 16px;*/
    /*line-height: 24px;*/
    /*letter-spacing: 0.08em;*/
    /*text-transform: uppercase;*/
    /*border-radius: 4px;*/
    color: #fff;
    cursor: pointer;
    z-index: 5;
    background: rgb(38, 38, 39);
    /*background: #00d301;*/
    background: -webkit-linear-gradient(
            -196deg
            ,#00d301,#36c275 50%,#00a562);
    background: -webkit-linear-gradient(
            164deg
            ,#00d301,#36c275 50%,#00a562);
    background: linear-gradient(
            286deg
            ,#00d301,#36c275 50%,#00a562);
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.insta-btn{
    background: rgb(38, 38, 39);
    /*background: #00d301;*/
    background: -webkit-linear-gradient(
            -196deg
            ,#9c27b0,#9c27b0 50%,#e91e63);
    background: -webkit-linear-gradient(
            164deg
            ,#9c27b0,#9c27b0 50%,#e91e63);
    background: linear-gradient(
            286deg
            ,#9c27b0,#9c27b0 50%,#e91e63);
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.main-btn:hover {
    color: #fff;
}

.btn-hover {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.btn-hover::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 0%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    top: 50%;
    left: 50%;
    padding: 50%;
    z-index: -1;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(0);
    transform: translate3d(-50%, -50%, 0) scale(0);
}

.btn-hover:hover::after {
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1.3);
    transform: translate3d(-50%, -50%, 0) scale(1.3);
}

/*===== All Section Title Style =====*/
.section-title h2 {
    font-size: 36px;
    line-height: 45px;
    font-weight: 500;
    margin-bottom: 10px;
}

/*===== All Preloader Style =====*/
.preloader {
    /* Body Overlay */
    position: fixed;
    top: 0;
    left: 0;
    display: table;
    height: 100%;
    width: 100%;
    /* Change Background Color */
    background: #fff;
    z-index: 9;
}

.preloader .loader {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.preloader .loader .ytp-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 64px;
    margin-left: -32px;
    z-index: 9;
    pointer-events: none;
}

.preloader .loader .ytp-spinner .ytp-spinner-container {
    pointer-events: none;
    position: absolute;
    width: 100%;
    padding-bottom: 100%;
    top: 50%;
    left: 50%;
    margin-top: -50%;
    margin-left: -50%;
    -webkit-animation: ytp-spinner-linspin 1568.2353ms linear infinite;
    animation: ytp-spinner-linspin 1568.2353ms linear infinite;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    right: 50%;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    left: 50%;
}

.preloader .loader .ytp-spinner-circle {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    width: 200%;
    height: 100%;
    border-style: solid;
    /* Spinner Color */
    border-color: #542DED #542DED #F9F9F9;
    border-radius: 50%;
    border-width: 6px;
}

.preloader .loader .ytp-spinner-left .ytp-spinner-circle {
    left: 0;
    right: -100%;
    border-right-color: #F9F9F9;
    -webkit-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.preloader .loader .ytp-spinner-right .ytp-spinner-circle {
    left: -100%;
    right: 0;
    border-left-color: #F9F9F9;
    -webkit-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

/* Preloader Animations */
@-webkit-keyframes ytp-spinner-linspin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes ytp-spinner-linspin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes ytp-spinner-easespin {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }
    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }
    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }
    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }
    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }
    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@keyframes ytp-spinner-easespin {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }
    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }
    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }
    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }
    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }
    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@-webkit-keyframes ytp-spinner-left-spin {
    0% {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@keyframes ytp-spinner-left-spin {
    0% {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@-webkit-keyframes ytp-right-spin {
    0% {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}

@keyframes ytp-right-spin {
    0% {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-55 {
    margin-top: 55px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-65 {
    margin-top: 65px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-75 {
    margin-top: 75px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-85 {
    margin-top: 85px;
}

.mt-90 {
    margin-top: 90px;
}

.mt-95 {
    margin-top: 95px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-105 {
    margin-top: 105px;
}

.mt-110 {
    margin-top: 110px;
}

.mt-115 {
    margin-top: 115px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-125 {
    margin-top: 125px;
}

.mt-130 {
    margin-top: 130px;
}

.mt-135 {
    margin-top: 135px;
}

.mt-140 {
    margin-top: 140px;
}

.mt-145 {
    margin-top: 145px;
}

.mt-150 {
    margin-top: 150px;
}

.mt-155 {
    margin-top: 155px;
}

.mt-160 {
    margin-top: 160px;
}

.mt-165 {
    margin-top: 165px;
}

.mt-170 {
    margin-top: 170px;
}

.mt-175 {
    margin-top: 175px;
}

.mt-180 {
    margin-top: 180px;
}

.mt-185 {
    margin-top: 185px;
}

.mt-190 {
    margin-top: 190px;
}

.mt-195 {
    margin-top: 195px;
}

.mt-200 {
    margin-top: 200px;
}

.mt-205 {
    margin-top: 205px;
}

.mt-210 {
    margin-top: 210px;
}

.mt-215 {
    margin-top: 215px;
}

.mt-220 {
    margin-top: 220px;
}

.mt-225 {
    margin-top: 225px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-65 {
    margin-bottom: 65px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-75 {
    margin-bottom: 75px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-85 {
    margin-bottom: 85px;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-95 {
    margin-bottom: 95px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-105 {
    margin-bottom: 105px;
}

.mb-110 {
    margin-bottom: 110px;
}

.mb-115 {
    margin-bottom: 115px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb-125 {
    margin-bottom: 125px;
}

.mb-130 {
    margin-bottom: 130px;
}

.mb-135 {
    margin-bottom: 135px;
}

.mb-140 {
    margin-bottom: 140px;
}

.mb-145 {
    margin-bottom: 145px;
}

.mb-150 {
    margin-bottom: 150px;
}

.mb-155 {
    margin-bottom: 155px;
}

.mb-160 {
    margin-bottom: 160px;
}

.mb-165 {
    margin-bottom: 165px;
}

.mb-170 {
    margin-bottom: 170px;
}

.mb-175 {
    margin-bottom: 175px;
}

.mb-180 {
    margin-bottom: 180px;
}

.mb-185 {
    margin-bottom: 185px;
}

.mb-190 {
    margin-bottom: 190px;
}

.mb-195 {
    margin-bottom: 195px;
}

.mb-200 {
    margin-bottom: 200px;
}

.mb-205 {
    margin-bottom: 205px;
}

.mb-210 {
    margin-bottom: 210px;
}

.mb-215 {
    margin-bottom: 215px;
}

.mb-220 {
    margin-bottom: 220px;
}

.mb-225 {
    margin-bottom: 225px;
}

.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-35 {
    padding-top: 35px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-45 {
    padding-top: 45px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-55 {
    padding-top: 55px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-65 {
    padding-top: 65px;
}

.pt-70 {
    padding-top: 70px;
}

.pt-75 {
    padding-top: 75px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-85 {
    padding-top: 85px;
}

.pt-90 {
    padding-top: 90px;
}

.pt-95 {
    padding-top: 95px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-105 {
    padding-top: 105px;
}

.pt-110 {
    padding-top: 110px;
}

.pt-115 {
    padding-top: 115px;
}

.pt-120 {
    padding-top: 120px;
}

.pt-125 {
    padding-top: 125px;
}

.pt-130 {
    padding-top: 130px;
}

.pt-135 {
    padding-top: 135px;
}

.pt-140 {
    padding-top: 140px;
}

.pt-145 {
    padding-top: 145px;
}

.pt-150 {
    padding-top: 150px;
}

.pt-155 {
    padding-top: 155px;
}

.pt-160 {
    padding-top: 160px;
}

.pt-165 {
    padding-top: 165px;
}

.pt-170 {
    padding-top: 170px;
}

.pt-175 {
    padding-top: 175px;
}

.pt-180 {
    padding-top: 180px;
}

.pt-185 {
    padding-top: 185px;
}

.pt-190 {
    padding-top: 190px;
}

.pt-195 {
    padding-top: 195px;
}

.pt-200 {
    padding-top: 200px;
}

.pt-205 {
    padding-top: 205px;
}

.pt-210 {
    padding-top: 210px;
}

.pt-215 {
    padding-top: 215px;
}

.pt-220 {
    padding-top: 220px;
}

.pt-225 {
    padding-top: 225px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-35 {
    padding-bottom: 35px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-45 {
    padding-bottom: 45px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-55 {
    padding-bottom: 55px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-65 {
    padding-bottom: 65px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-75 {
    padding-bottom: 75px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pb-85 {
    padding-bottom: 85px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pb-95 {
    padding-bottom: 95px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pb-105 {
    padding-bottom: 105px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-115 {
    padding-bottom: 115px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pb-125 {
    padding-bottom: 125px;
}

.pb-130 {
    padding-bottom: 130px;
}

.pb-135 {
    padding-bottom: 135px;
}

.pb-140 {
    padding-bottom: 140px;
}

.pb-145 {
    padding-bottom: 145px;
}

.pb-150 {
    padding-bottom: 150px;
}

.pb-155 {
    padding-bottom: 155px;
}

.pb-160 {
    padding-bottom: 160px;
}

.pb-165 {
    padding-bottom: 165px;
}

.pb-170 {
    padding-bottom: 170px;
}

.pb-175 {
    padding-bottom: 175px;
}

.pb-180 {
    padding-bottom: 180px;
}

.pb-185 {
    padding-bottom: 185px;
}

.pb-190 {
    padding-bottom: 190px;
}

.pb-195 {
    padding-bottom: 195px;
}

.pb-200 {
    padding-bottom: 200px;
}

.pb-205 {
    padding-bottom: 205px;
}

.pb-210 {
    padding-bottom: 210px;
}

.pb-215 {
    padding-bottom: 215px;
}

.pb-220 {
    padding-bottom: 220px;
}

.pb-225 {
    padding-bottom: 225px;
}

/*===========================
    02.HEADER css
===========================*/
/*===== NAVBAR =====*/






/*@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-btn {
    position: absolute;
    right: 60px;
  }
}*/

@media (max-width: 767px) {
    .header-btn {
        /*    position: absolute;
            right: 50px;
            display: none;*/
    }
    /*  .header-btn a i {
        display: none;
      }*/
}









/*============================
counter up css
============================= */
.counter-area {
    margin-top: -65px;
    position: relative;
    z-index: 1;
}

.counter-area .counter-wrapper {
    background: #fff;
    -webkit-appearance: none;
    -webkit-box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.32);
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.32);
    border-radius: 16px;
    padding: 30px 43px 10px;
    padding-bottom: 10px;
}

.counter-area .counter-wrapper .single-counter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .counter-area .counter-wrapper .single-counter {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .counter-area .counter-wrapper .single-counter {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
    }
}

.counter-area .counter-wrapper .single-counter .counter-icon {
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 15px;
}

@media (max-width: 767px) {
    .counter-area .counter-wrapper .single-counter .counter-icon {
        margin-left: auto;
        margin-right: auto;
    }
}

.counter-area .counter-wrapper .single-counter .counter-icon i {
    font-size: 44px;
    line-height: 44px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #542DED;
}

.counter-area .counter-wrapper .single-counter .counter-text span {
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #4C4C4C;
    display: block;
}

.counter-area .counter-wrapper .single-counter .counter-text span.countup {
    font-weight: 500;
    font-size: 36px;
    line-height: 45px;
    color: #111111;
}


.icon-style {
    width: 45px;
    height: 45px;
    border-radius: 5px;
    margin-bottom: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 20px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

/*==================================================================
                           11. Progress Bars css
==================================================================*/
.promo h4 {
    color: #542DED;
}

.single-progress-bar-horizontal {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    position: relative;
    align-items: center;
    margin-top: 30px;
}

@media (max-width: 767px) {
    .single-progress-bar-horizontal {
        display: block;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-progress-bar-horizontal {
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.single-progress-bar-horizontal .progress-text {
    padding-right: 10px;
}

@media (max-width: 767px) {
    .single-progress-bar-horizontal .progress-text {
        padding-bottom: 10px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-progress-bar-horizontal .progress-text {
        padding-bottom: 0;
    }
}

.single-progress-bar-horizontal .progress-text p {
    font-weight: 500;
    font-size: 14px;
}

.single-progress-bar-horizontal .progress-bar-inner .bar-inner {
    width: 100%;
    height: 50px;
    border-radius: 50px;
    background-color: #EFEFEF;
    position: absolute;
    overflow: hidden;
}

.single-progress-bar-horizontal .progress-bar-inner .bar-inner .progress-horizontal {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50px;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
    width: 0;
    position: relative;
}

.single-progress-bar-horizontal.ready .progress-bar-inner .bar-inner .progress-horizontal {
    background-color: #542DED;
    width: 80%;
}

/*============================
showcase css
============================= */
.single-showcase {
    -webkit-appearance: none;
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    text-align: center;
    background: #fff;
}

.single-showcase:hover {
    -webkit-appearance: none;
    -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.32);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.32);
}

.single-showcase .showcase-img {
    overflow: hidden;
    border-radius: 8px 8px 0 0;
}

.single-showcase .showcase-img a {
    display: block;
}

.single-showcase .showcase-img a img {
    width: 100%;
}

.single-showcase .showcase-info {
    padding: 20px 0;
}

.single-showcase .showcase-info h4 a {
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #111111;
    margin-bottom: 10px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.single-showcase .showcase-info h4 a:hover {
    color: #542DED;
}

.single-showcase .showcase-info p {
    display: block;
    font-size: 16px;
    line-height: 22px;
    color: #4C4C4C;
    margin: 0 15px;
}

.eCommerce-area {
    position: relative;
    z-index: 1;
}

.uikit-area {
    position: relative;
    z-index: 1;
}

.bg-shape {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    z-index: -1;
}

.bg-shape.bottom {
    bottom: 0;
}

.bg-shape.top {
    top: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

/*============================
testimonial css
============================= */
.testimonial-area .single-testimonial {
    margin: 30px 0px 60px;
}

.testimonial-area .single-testimonial:hover .testimonial-content-wrapper {
    -webkit-appearance: none;
    -webkit-box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.32);
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.32);
}

.testimonial-area .single-testimonial .testimonial-content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 15px;
    -webkit-appearance: none;
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 15px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.testimonial-area .single-testimonial .testimonial-content-wrapper .testimonial-img {
    margin-right: 15px;
    min-width: 60px;
}

.testimonial-area .single-testimonial .testimonial-content-wrapper .testimonial-img img {
    width: 100%;
    border-radius: 50%;
}

.testimonial-area .single-testimonial .testimonial-content-wrapper .testimonial-content p {
    margin-bottom: 30px;
}

.testimonial-area .single-testimonial .testimonial-content-wrapper .testimonial-content h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
}

.testimonial-area .single-testimonial .testimonial-content-wrapper .testimonial-content span {
    display: block;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #868AAA;
}

.testimonial-area .testimonial-slider-wrapper {
    position: relative;
}

.tns-nav {
    position: absolute;
    bottom: -50px;
    right: auto;
    left: auto;
    z-index: 9;
    width: 100%;
    text-align: center;
}

.tns-nav button {
    width: 16px;
    height: 16px;
    background: rgba(0, 0, 0, 0.16);
    border-radius: 32px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    padding: 0px;
    border: none;
    margin: 0 5px;
}

.tns-nav button.tns-nav-active, .tns-nav button:hover {
    background: #542DED;
}

/*============================
pricing css
============================= */
.pricing-area {
    background: #f6f4fc;
}

.pricing-area .single-pricing {
    -webkit-appearance: none;
    -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 8px;
    background: #fff;
    padding: 40px 30px;
    padding-top: 0;
    margin-bottom: 30px;
}

.pricing-area .single-pricing.active, .pricing-area .single-pricing:hover {
    -webkit-appearance: none;
    -webkit-box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.32);
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.32);
}

.pricing-area .single-pricing .pricing-img {
    margin-bottom: 40px;
}

.pricing-area .single-pricing .pricing-info {
    margin-bottom: 40px;
}

.pricing-area .single-pricing .pricing-info .package-name {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #4C4C4C;
    margin-bottom: 8px;
}

.pricing-area .single-pricing .pricing-info .package-price {
    font-weight: 500;
    font-size: 44px;
    line-height: 55px;
    margin-bottom: 12px;
}

.pricing-area .single-pricing .pricing-info .package-price span {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #7d7d7d;
}

.pricing-area .single-pricing .pricing-info p {
    font-size: 16px;
    line-height: 20px;
    color: #542DED;
    font-weight: 700;
}

.pricing-area .single-pricing .package-details {
    margin-bottom: 40px;
}

.pricing-area .single-pricing .package-details ul li {
    margin-bottom: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 15px;
}

.pricing-area .single-pricing .package-details ul li i {
    margin-right: 8px;
}

.pricing-area .single-pricing .package-details ul li i.lni-checkmark {
    color: #7CB637;
}

.pricing-area .single-pricing .package-details ul li i.lni-close {
    color: #FF4B22;
}

.pricing-area .single-pricing.lite-package .pricing-btn a {
    background: none;
    border: 2px solid #542DED;
    color: #542DED;
}

.pricing-area .single-pricing.lite-package .pricing-btn a:hover {
    background: #542DED;
    color: #fff;
}

/*============================
co-created css
============================= */
.co-created-area .co-created-slider-wrapper {
    position: relative;
}

.co-created-area .co-created-slider-wrapper .co-created-item {
    margin: 0 auto 10px;
    text-align: center;
}

.co-created-area .co-created-slider-wrapper .tns-controls {
    position: absolute;
    width: 100%;
    z-index: 9;
}

.co-created-area .co-created-slider-wrapper .tns-controls button {
    background: none;
    border: none;
}

.co-created-area .co-created-slider-wrapper .tns-controls button i {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: 15px;
}

.co-created-area .co-created-slider-wrapper .tns-controls button i.lni-chevron-right {
    left: auto;
    right: 0;
}

/*============================
faq/contact css
============================= */

.contact-area .contact-form-wrapper {
    padding: 100px 50px;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.contact-area .contact-form-wrapper:hover {
    -webkit-appearance: none;
    -webkit-box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
    .contact-area .contact-form-wrapper {
        padding: 50px 30px;
    }
}

.banner-area {
    margin-top: 90px;
    padding: 70px 0;
    background: #542DED;
}

.banner-area .banner-content h1 {
    color: #fff;
    margin-bottom: 15px;
    font-size: 36px;
    font-weight: 500;
}

.banner-area .banner-content p {
    margin-bottom: 20px;
}

.banner-area .banner-content .breadcrumb {
    background: none;
    padding: 0;
}

.banner-area .banner-content .breadcrumb li {
    color: #fff;
    text-transform: uppercase;
}

.banner-area .banner-content .breadcrumb li::before {
    color: #fff;
}

.banner-area .banner-content .breadcrumb li a {
    color: rgba(255, 255, 255, 0.7);
}

.banner-area .banner-content .breadcrumb li a:hover {
    color: #fff;
}

/*============================
footer css
============================= */

/*# sourceMappingURL=style.css.map */













@import url("https://fonts.googleapis.com/css?family=Alata&display=swap");
@import url('https://fonts.googleapis.com/css?family=Mukta:700');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,700,800');


@font-face {
    font-family: 'GochiHandCyrillic';
    src: url('../fonts/3174-font.otf');
    font-weight: normal;
    font-style: normal;
}

/*body {
  font-family: "Mukta", sans-serif;
}*/

.camp_card{
    margin-left:100px;
}
.nav-item{
    line-height: 100px;
}
.expand_container{
    display: none;
}

.expand_container.active{
    display: block;
}





/* ? */

.modal-backdrop{
    z-index: 99;
}

.certain_text{
    visibility: visible;
    animation-delay: 0.6s;
    animation-name: fadeInUp;
    margin-top: 1rem;
    color:white!important;
    text-shadow: 1px 1px 6px rgb(0 0 0 / 50%);
    text-transform: uppercase;
    opacity: 0.75!important;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 19.6px;
    font-weight: 700;
}


.poll_card::before {
    content: "";
    background-image: url('../img/flight.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.35;
}

.wrapper {
    position: relative;
}
.wrapper:before, .wrapper:after {
    position: absolute;
    top: 0;
    z-index: 1;
    content: "";
    display: block;
    width: 20px;
    height: 100%;
}
.wrapper:before {
    left: 0;
    background: linear-gradient(90deg, #22222208, transparent);

}
.wrapper:after {
    right: 0;
    background: linear-gradient(-90deg, #22222208, transparent);
}

.items {
    position: relative;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0;
    cursor: pointer;
}
.items.active {
    cursor: grab;
}

.item {
    display: inline-block;
    margin-left: 35px;
    user-select: none;
    width: 50%;
    height: 130px;
    color: #222;
    font-size: 33px;
    font-weight: bold;
    line-height: 130px;
}
.item:last-child {
    margin-right: 20px;
}








.hidden{
    display: inline;
}
.hidden-web{
    display: none;
}






/* ? */


.box {
    z-index: 9;
    align-items: center;
    background-color: #232432;
    display: flex;
    height: 100px;
    justify-content: center;
    padding: 0 36px;
    width: 100%;
    bottom: 0;
    position: fixed;
}

.progress {
    background-color: rgba(255,255,255,.2);
    height: 24px;
    position: relative;
    width: 100%;
}

.inner {
    background-color: #296eff;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
}

/*
   Loading-swipe animation duration should change,
   depending on the width of the progress element.
*/

.inner:after {
    animation: loading-swipe 3s linear infinite 1.5s;
    background: linear-gradient(270deg, #6295ff, transparent);
    bottom: 0;
    content: '';
    left: -48px;
    position: absolute;
    top: 0;
    width: 48px;
}

@keyframes loading-swipe {
    from {left: -48px;}
    to {left: calc(512px + 48px);}
}

/* ? */

.pw-panel{position:fixed;width:100%;min-width:440px;z-index:9;border:none;background-color:#fff;transition:all .3s;transition-property:right,left,top,bottom}.pw-panel-resizer{position:absolute;background-color:#f2f2f2;cursor:s-resize}.pw-panel-resizer__orientation_top{bottom:-8px}.pw-panel-resizer__orientation_bottom{top:-8px}.pw-panel-resizer__orientation_bottom,.pw-panel-resizer__orientation_top{left:0;width:100%;height:8px}.pw-panel-resizer__orientation_left{right:-8px}.pw-panel-resizer__orientation_right{left:-8px}.pw-panel-resizer__orientation_left,.pw-panel-resizer__orientation_right{height:100%;top:0;width:8px;cursor:e-resize}.pw-panel-resizer__orientation_left ul,.pw-panel-resizer__orientation_right ul{flex-direction:column}.pw-panel-resizer ul{height:inherit;padding:0;margin:0;display:flex;justify-content:center;align-items:center;pointer-events:none;list-style:none}.pw-panel-resizer ul li{margin:2px;height:4px;width:4px;background-color:#70757a;border-radius:50%}.pw-panel-resizer:hover{background-color:#e6e6e6;opacity:.8}.pw-panel__orientation_top{max-height:90vh;top:-100%;left:0;width:100%;height:300px}.pw-panel__orientation_bottom{max-height:90vh;bottom:-100%;left:0;width:100%;height:300px}.pw-panel__orientation_left{max-width:90vw;top:0;left:-100%;width:450px;height:100%}.pw-panel__orientation_right{max-width:90vw;top:0;right:-100%;width:450px;height:100%}.pw-panel__iframe{width:100%;height:100%;border:none}.pw-panel__iframe--resizing{outline:1px solid #f2f2f2;pointer-events:none}@media (max-width:450px){.pw-panel{display:none}}


.expand{
    display: flex;
    /*margin-left: 10px;*/
    /*justify-content: center;*/
    align-items: center;
    /*background: #f1f3f9;*/
    /*height: 200px;*/
    /*font-family: "Muli", Arial, sans-serif;*/
}

#hicon {
    position: fixed;
    display: block;
    left: 25px;
    bottom: -42px;
}
#hicon svg {
    width: 78px;
    height: 78px;
    fill: rgba(146, 156, 208, 0.8);
}

.nav-list {
    background: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    height: auto;
    color: black;
    /*border: 1px solid #d5dbed;*/
    border-radius: 16px;
    -webkit-appearance: none;
    -webkit-box-shadow: 0 6px 30px -10px #d5dbed;
    box-shadow: 0 6px 30px -10px #d5dbed;
}

.nav-sub-sect,
.nav-sect {
    position: relative;
    padding: 10px 15px;
    border-radius: 12px;
    cursor: pointer;
    transition: background 299ms ease, opacity 199ms ease;
    text-align: center;
}
.nav-sub-sect a,
.nav-sect a {
    text-decoration: none;
    color: #333333;
    transition: color 299ms ease;
}

.nav-sect:hover {
    transition: background 299ms ease;
    background: #eff4ff;
}
.nav-sect:hover .url {
    color: #6e80a5;
    transition: color 299ms ease;
}
.nav-sect:hover .line-1 {
    stroke: #6e80a5;
    transition: all 199ms ease;
}
.nav-sect:hover .line-2 {
    stroke: #6e80a5;
    transition: all 199ms ease;
}

.nav-icon {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}
.nav-icon .url {
    margin-right: 5px;
}

.nav-sect {
    opacity: 1;
}

.nav-sub-list {
    border-radius: 16px;
    position: absolute;
    background: #fff;
    width: auto;
    padding: 15px;
    top: 38px;
    right: 0;
    -webkit-appearance: none;
    -webkit-box-shadow: 0 6px 30px -10px #d5dbed;
    box-shadow: 0 6px 30px -10px #d5dbed;
    overflow: hidden;
    opacity: 0;
    transform: scale(0);
    transition: opacity 299ms ease 199ms, transform 0ms ease;
}

.nav-sub-sect {
    text-align: left;
}

.nav-sub-sect:hover {
    transition: background 299ms ease;
    background: #eff4ff;
}
.nav-sub-sect:hover .sub-url {
    color: #6e80a5;
    transition: color 299ms ease;
}

.nav-sub-list-on {
    opacity: 1;
    z-index: 9;
    width: 300px;
    transform: scale(1);
    transition: opacity 299ms ease 199ms, transform 0ms ease;
}

.chevron-up {
    transform: rotate(0);
    transition: color 299ms ease, transform 299ms ease;
}

.op {
    opacity: 1;
    transition: opacity 299ms ease;
}

.less-op {
    opacity: 0.5;
    transition: opacity 299ms ease;
}

.less-op:hover {
    opacity: 1;
    transition: opacity 299ms ease;
}

line {
    stroke-width: 5px;
}

.arrow2 {
    transform: translatey(0px);
    transition: all 199ms ease;
}

.line-1 {
    transform-origin: 17% 48%;
    transform: rotate(40deg) translatex(6px);
    stroke: #333333;
    transition: all 199ms ease;
}

.line-2 {
    transform-origin: 83% 48%;
    transform: rotate(-40deg) translatex(-6px);
    stroke: #333333;
    transition: all 199ms ease;
}

.svg-arrow {
    transform: scale(1.4);
}

.arrow-active {
    transform: translatey(2px);
    transition: all 199ms ease;
}

.line-1-active {
    transform-origin: 17% 40%;
    transform: rotate(0deg) translatex(3px);
    stroke: #333333;
    transition: all 199ms ease;
}

.line-2-active {
    transform-origin: 83% 40%;
    transform: rotate(0deg) translatex(-3px);
    stroke: #333333;
    transition: all 199ms ease;
}



.icon-button{
    display: none;
}
.main_title_camp{
    font-size: 90px;
    text-shadow: 1px 1px 6px rgb(0 0 0 / 50%);
    color: white;
    margin: 0px;
    text-align: center;
    /*font-family: 'Gochi Hand', cursive;*/
    z-index: 9;
}

.white{
    color: white;
}
.container{
    max-width: 1170px;
    /*margin: 50px;*/
}
.card_panel {
    border-radius: 20px;
    background: #fff;
    color: white;
    padding: 5px;
    /*margin: 10px;*/
    -webkit-appearance: none;
    -webkit-box-shadow: 0 3px 4px 0 rgb(0 0 0 / 4%), 0 7px 18px 0 rgb(0 0 0 / 5%), 0 12px 24px 0 rgb(0 0 0 / 8%);
    box-shadow: 0 3px 4px 0 rgb(0 0 0 / 4%), 0 7px 18px 0 rgb(0 0 0 / 5%), 0 12px 24px 0 rgb(0 0 0 / 8%);
}
.transparent_panel {
    background: #ffffff8a;
}

.black-section h2{
    background: -webkit-linear-gradient(#85CDFF, #28EB70);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.transparent_panel span{
    color: black;
}

.card_panel.black {
    border-radius: 20px;
    background: #1F1F1F;
    color: white;
}

.card_panel.black h6{
    color: white;
}
.card_panel.black p{
    color: white;
}
.white-section{
    /*margin: 50px auto;*/
}
.story{
    margin:0px;
    border-radius: 10px;
}
.tag {
    position: absolute;
}
.title {
    color: yellow;
    font-size: 120px;
    position: absolute;
    top: -30px;
    z-index: -1;
    left: 0px;
}
.black-section{
    background-color: #d6e7de;
    padding: 15px;
}
.black_row{
    width: 1170px;
    margin: 50px auto;
}
.white-section{
    padding: 15px;
}
.white-section h2{
    background: transparent;
    -webkit-text-fill-color: black;
    color: black;
}
.white-section h6{
    color: black;
}
.white-section p{
    color: black;
}




.control_arrow:hover,.control_arrow:focus {
    transform: translateY(-4px);
    -webkit-appearance: none;
    -webkit-box-shadow: 0 22px 43px rgb(0 0 0 / 15%);
    box-shadow: 0 22px 43px rgb(0 0 0 / 15%);
}
.call2action{
    margin: 50px auto;
    border-radius: 15px;
    background-color: #ffc106;
    padding: 15px !important;
    /*  background-color: #85CDFF;
      background-image: url(../img/flights.svg);
          filter: invert(100%);
        -webkit-filter: invert(100%);*/
}


.images{
    border-radius: 30px;
    /*margin: 10px;*/
    background-size: cover;
    cursor: pointer;
}
.page-scroll {
    color: white;
}
.battery {
    -webkit-appearance: none;
    -webkit-box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 24%);
    box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 24%);
    margin: 200px 170px -90px;
    height: 90px;
    border-radius: 20px;
}
.character{
    margin-left: -270px;
    margin-top: 100px;
    max-width: 180%;
    border: none;
}
.tags{
    height: 500px;
    margin: 0 0 60px 0;
}
.nextcamp {
    margin-left: 40px;
}

.logos {
    width: auto;
    height: 100px;
}

.carousel-indicators li{
    background-color: #000;
}

.black-section .carousel-indicators li{
    background-color: #fff;
}

.image_camp{
    height: 250px;
    background-position: center;
}

.camp_text{
    padding: 10px 15px 100px 15px
}

.form-block{
    padding: 0 100px
}
.camp_video{
    width: 100%;
    height: 350px;
}
.camp_intro{
    height: 100vh;
    margin:0;
    padding: 150px 0 10px;
    background-position-y: center;
    background-size: cover;
}

.btn-text{
    font-size: 18px !important;
    text-align: center;
    color: white !important;
}

.disactive{
    background: rgb(162 162 162);
}

.sideways li a{
    padding: 20px;
    margin: 5px;
}

.sideways li a.active{
    background-color: #1F1F1F;
    border-radius: 20px;
}

.sideways li a.active h4{
    color: #fff;
}

.sideways li a.active span{
    color: #fff;
}
.sideways li a.active p{
    color: #fff;
}

.gallery-title
{
    font-size: 36px;
    color: #42B32F;
    text-align: center;
    font-weight: 500;
    margin-bottom: 70px;
}
.gallery-title:after {
    content: "";
    position: absolute;
    width: 7.5%;
    left: 46.5%;
    height: 45px;
    border-bottom: 1px solid #5e5e5e;
}
.filter-button
{
    font-size: 18px;
    border: 1px solid #42B32F;
    border-radius: 5px;
    text-align: center;
    color: #42B32F;
    margin-bottom: 30px;

}
.filter-button:hover
{
    font-size: 18px;
    border: 1px solid #42B32F;
    border-radius: 5px;
    text-align: center;
    color: #ffffff;
    background-color: #42B32F;

}
.btn-default:active .filter-button:active
{
    background-color: #42B32F;
    color: white;
}

.btns {
    width: var(--slider-width);
    /* border-bottom: 1px solid #ddd; */
    margin: 10px auto;
    padding: 10px 0;
    text-align: right;
    /*margin-bottom: 3rem;*/
}

.btns button {
    background-color: transparent;
    border: none;
    padding: 0;
}

.btns button svg {
    width: 40px;
    cursor: pointer;
}

.port-image
{
    width: 100%;
}

.gallery_product
{
    margin-bottom: 30px;
}





.camp_smalltext{
    top: 220px;
}


.image_icon{
    width: 50px;
    top: 30px;
    right: 30px;
    position: absolute;
}

.pop .image_icon{
    display:none;

}
.pop:hover .image_icon {
    display:block;
}
::-ms-clear {
    display: none;
}

.form-control-clear {
    z-index: 10;
    pointer-events: auto;
    cursor: pointer;
}


.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
    /* 3 */
}









/*=====================================
calendar
======================================= */

/**, *:before, *:after {
-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}*/

/*body {*/
/* overflow: hidden;
 font-family: 'HelveticaNeue-UltraLight', 'Helvetica Neue UltraLight', 'Helvetica Neue', Arial, Helvetica, sans-serif;
 font-weight: 100;*/
/*color: rgba(255, 255, 255, 1);*/
/* margin: 0;
 padding: 0;*/
/*background: #4A4A4A;*/
/* -webkit-touch-callout: none;
 -webkit-user-select: none;
 -khtml-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;*/
/*}*/





/* slider */
/*:root {
    --card-width: 360;
    --slider-width: calc(var(--card-width) * 3px * 6px);
    --slide-scroll: 240;
}
*/

/*.btns {
    width: var(--slider-width);
    border-bottom: 1px solid #ddd;
    margin: 10px auto;
    padding: 10px 0;
    text-align: right;
    margin-bottom: 3rem;
}*/

/*.btns button {
    background-color: transparent;
    border: none;
    padding: 0;
}

.btns button svg {
    width: 40px;
    cursor: pointer;
}

.btns button svg:hover {
    fill: #fff;
}*/
/*
.slider {
    overflow: hidden;
    width: var(--slider-width);
    margin: 0 auto;
}

.slides {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    transform: translateX(-300px);
    transition: transform 0.2s;
    padding: 30px 0;
}

.slider .card {
    width: calc(var(--card-width) * 1px);
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    margin: 0 20px;
    opacity: 0.4;
    /*box-shadow: 0 0 15px #aca5a5;*/
/*}

.slider .card-focus {
    opacity: 1;
    transform: scale(1.05);
}
#albania.slider .card .img {
    width: 100%;
    height: 300px;
    background: url('../img/villa1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    border-radius: 10px 10px 0 0;
}*/

/*#turkey.slider .card .img {
    width: 100%;
    height: 300px;
    background: url('../img/villa21.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    border-radius: 10px 10px 0 0;
}*/

/*#albania.slider .card:nth-child(1) .img {
    background-image: url('../img/villa2.jpg');
}

#albania.slider .card:nth-child(2) .img  {
    background-image: url('../img/villa3.jpg');
}

#albania.slider .card:nth-child(3) .img {
    background-image: url('../img/villa4.jpg');
}

#albania.slider .card:nth-child(4) .img  {
    background-image: url('../img/villa1.jpg');
}

#albania.slider .card:nth-child(5) .img{
    background-image: url('../img/001.png');
}
*/

/*#turkey.slider .card:nth-child(1) .img, .slider .card:nth-child(5) .img {
    background-image: url('../img/villa22.jpeg');
}

#turkey.slider .card:nth-child(2) .img, .slider .card:nth-child(6) .img {
    background-image: url('../img/villa23.jpeg');
}

#turkey.slider .card:nth-child(3) .img, .slider .card:nth-child(7) .img {
    background-image: url('../img/villa24.jpeg');
}

#turkey.slider .card:nth-child(4) .img, .slider .card:nth-child(8) .img {
    background-image: url('../img/villa21.jpeg');
}


#egypt.slider .card:nth-child(1) .img, .slider .card:nth-child(5) .img {
    background-image: url('../img/villa32.jpeg');
}

#egypt.slider .card:nth-child(2) .img, .slider .card:nth-child(6) .img {
    background-image: url('../img/villa33.jpeg');
}

#egypt.slider .card:nth-child(3) .img, .slider .card:nth-child(7) .img {
    background-image: url('../img/villa34.jpeg');
}

#egypt.slider .card:nth-child(4) .img, .slider .card:nth-child(8) .img {
    background-image: url('../img/villa31.jpeg');
}*/
/*.slider .card>p:nth-of-type(1) {
    margin: 10px 0 0px 0;
    font-size: 0.8rem;
    color: crimson;
}*/

/*.slider .card>p:nth-of-type(2) {
    margin: 1rem 12px;
}*/
/*
.slider .card h2 {
    margin-bottom: 10px;
}

.slider .card div:nth-of-type(2) {
    color: #fff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 10px;
}*/

/*.slider .card div p {
    margin: 0;
    padding: 15px 0;
    text-align: center;
    font-size: 0.7rem;
}*/

/*.slider .card div:nth-of-type(2) p:nth-child(1) {
    background-color: #405de6;
}

.slider .card div:nth-of-type(2) p:nth-child(2) {
    background-color: #e1306c;
}

.slider .card div:nth-of-type(2) p:nth-child(3) {
    background-color: #f77737;
}

.slider .card div p:not(p:last-child) {
    border-right: 1px solid #ddd;
}

.slider .card div p span {
    display: block;
    font-weight: 800;
    font-size: 1.05rem;
}*/

/* Large devices */

/*@media only screen and (max-width: 992px) {
    :root {
        --card-width: 300;
    }
    .slides {
        transform: translateX(-240px);
    }
    .slider .card .img {
        height: 130px
    }*/
/*    .slider .card>p:nth-of-type(2) {
        font-size: 14px;
        margin: 10px 5px;
    }
    .slider .card div p {
        padding: 13px 0;
    }*/
/*}*/

/* Medium devices */

/*@media only screen and (max-width: 768px) {*/
/*    .slider .card>p:nth-of-type(1) {
        font-size: 0.7rem;
    }*/
/*    .slider .card h2 {
        font-size: 20px;
    }*/
/*    .slider .card>p:nth-of-type(2) {
        font-size: 12px;
    }
    .slider .card div p {
        padding: 10px 0;
    }
    .slider .card div p span {
        font-size: 0.85rem;
    }*/
/*}*/













/*.main-btn.active {
    background-color: #85CDFF;
    color: black;
  }*/

/*img:hover{
  border-left: solid 9px #000;
  border-bottom: solid 9px #000;
}*/


/*.transparent_panel h2{
  color: black !important;
  background: transparent;
     -webkit-background-clip: text;
     -webkit-text-fill-color: black;
}*/



/*.blok{margin:30px auto;}*/


/*.tabs-left, {
  border-bottom: none;
  padding-top: 2px;
}
.tabs-left {
  border-right: 1px solid #ddd;
}
.tabs-left>li, .tabs-right>li {
  float: none;
  margin-bottom: 2px;
}
.tabs-left>li {
  margin-right: -1px;
}
.tabs-left>li.active>a,
.tabs-left>li.active>a:hover,
.tabs-left>li.active>a:focus {
  border-bottom-color: #ddd;
  border-right-color: transparent;
}
.tabs-left>li>a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display:block;
}*/


/*

.transition {
  transition: all 0.4s ease-in-out;
}

body {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.wrapper {
  font-size: 30px;
  margin-top: -3%;
  color: white;
  width: 800px;
    text-shadow: 1px 1px 6px rgb(0 0 0 / 50%);
      font-size: 76px;
    line-height: 87px;
    font-weight: 700;
}

.list {
  display: inline-block;
  position: relative;
  margin-left: 6px;
  width: 600px;
}
.list ul {
  text-align: left;
  width: 600px;
  position: absolute;
  padding: 0;
  top: 0;
  left: 0;
  display: none;
}
.list ul .active {
  display: block;
}
.list li {
  list-style: none;
}
.list li:first-child a {
  color: white;
}
.list a {
  transition: all 0.4s;
  color: white;
  position: relative;
}
.list a:after {
  position: absolute;
  content: "";
  height: 5px;
  width: 0;
  left: 0;
  background: white;
  bottom: 0;
  transition: all 0.4s ease-out;
}
.list a:hover {
  cursor: pointer;
  color: white;
}
.list a:hover:after {
  width: 100%;
}

select {
  display: inline;
  border: 0;
  width: auto;
  margin-left: 10px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-bottom: 2px solid white;
  color: white;
  transition: all 0.4s ease-in-out;
}
select:hover {
  cursor: pointer;
}
select option {
  border: 0;
  border-bottom: 1px solid white;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.placeholder {
  border-bottom: 4px solid;
  cursor: pointer;
  color:white !important;
}
.placeholder:hover {
  color: white;
}

*/




/*lang button*/

.header__lang {
    margin: auto;
    position: relative;
}
.header__lang a {
    border: none;
}
.header__lang a:focus {
    outline: none;
}
.header__lang-open-list-btn {
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    margin: auto;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.15s ease;
}
.header__lang-open-list-btn:hover {
    transform: scale(0.95);
}
.header__lang-open-list-btn:focus {
    transition: 0.15s ease;
    transform: scale(0.95);
}
.header__lang-open-list-btn:active {
    transition: 0.15s ease;
    transform: scale(0.9);
}
.header__lang-list {
    list-style: none;
    background-color: white;
    -webkit-appearance: none;
    -webkit-box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.08);
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.08);
    border: 1px solid #f4f4f4;
    border-radius: 7px;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s ease, visibility 0.15s ease, transform 0.15s ease;
    top: 120%;
    left: 50%;
    transform: translate(-50%, 30px);
    padding-left: 0px;
}
.header__lang-list--visible {
    opacity: 1;
    padding: 2px;
    visibility: visible;
    transform: translate(-50%, 0px);
}
.header__lang-btn {
    cursor: pointer;
    padding: 10px 15px;
    text-transform: uppercase;
    font-weight: 700;
    transition: color 0.15s ease;
}
.header__lang-btn:hover {
    color: #ff6425;
}




/**
 * Tooltip Styles
 */

.tooltip-toggle {
    cursor: pointer;
    position: relative;
}
.tooltip-toggle::before {
    position: absolute;
    top: -65px;
    /* left: -80px; */
    border: 1px solid #00000073;
    background-color: #0000006b;
    border-radius: 5px;
    color: #fff;
    line-height: 14px;
    content: attr(aria-label);
    padding: 10px;
    text-transform: none;
    transition: all 0.5s ease;
    width: 260px;
    box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px !important;
}
.tooltip-toggle::before, .tooltip-toggle::after {
    color: #fff;
    font-family: monospace;
    font-size: 12px;
    opacity: 0;
    pointer-events: none;
    text-align: left;
}
.tooltip-toggle:focus::before, .tooltip-toggle:focus::after, .tooltip-toggle:hover::before, .tooltip-toggle:hover::after {
    opacity: 1;
    transition: all 0.75s ease;
}


.iti__flag {background-image: url("../img/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {background-image: url("../img/flags@2x.png");}
}


.tc {
    text-align: center;
}


.instagram {
    background: linear-gradient(to bottom, #405de6 0%, #833ab4 10%, #c13584 25%, #e1306c 50%, #f56040 75%, #fcaf45 100%);
    transition-property: background;
    transition-duration: 200ms;
}

.instagram:hover {
    background: #1e2469;
}

/* Set-up styling (not key to the approach illustrated here) */
.instagram {
    display: inline-block;
    padding: 1em;
    border-radius: 50%;
    line-height: 1;
}

.instagram svg {
    display: block;
}

/* Visually hide text */
.sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    overflow: hidden;
    position: absolute !important;
}

@keyframes wifianimation {
    0% {
        opacity: 0.4;
    }
    5% {
        opacity: 1;
    }
    6% {
        opacity: 0.1;
    }
    100% {
        opacity: 0.1;
    }
}
