.camp_date {
    color: blue; font-size: 29px;font-weight: 700; text-transform: uppercase; padding:10px 0;
}

.main-btn {
    margin-left:0;padding: 12px;
    width: 100%;
    vertical-align: middle;
    border-radius: 10px;
}

.apply-row {
    padding: 15px 0;
}

.wrap:global(#when) {
    margin-top: 0;
}