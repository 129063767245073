.member-text {
    margin-left: 20px;
}

.active {
    background-color: #1F1F1F;
    border-radius: 20px;
    color: #fff;
}

.active h4,
.active p {
    color: #fff;
}
