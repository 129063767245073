@import url("https://fonts.googleapis.com/css?family=Alata&display=swap");
@import url('https://fonts.googleapis.com/css?family=Mukta:700');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,700,800');


@font-face {
    font-family: 'GochiHandCyrillic';
    src: url('../fonts/3174-font.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LineIcons';
    src: url('../fonts/LineIcons.woff2') format('woff2'), url('../fonts/LineIcons.woff') format('woff'), url('../fonts/LineIcons.ttf');
    font-weight: normal;
    font-style: normal;
}
