.feature-area {
    height: 100%;
}

.card_panel {
    padding: 20px;
    background-color: #11ad68;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.text-item {
    padding: 10px 0;
}

.title {
    color: black;  margin: 5px 0;font-size: 30px; text-align: left;
}

.camp_text {
    text-align: center;padding: 0px;
}

.header-btn {
    padding: 10px;
}

.btn-hover {
    width: 100%;margin-right: 2px;font-size: 14px;color: white; background-color: black;
    padding: 12px;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 10px;
}
