.hero-area {
    background-image: url('../../assets/img/wallpaper/about.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    margin: 0;
    padding: 220px 0 10px;
}

.feature-area-first {
    margin-top: -143px;
}

.card-panel {
    border-radius: 20px;
    background: #FFFFFF;
    color: #fff;
}

.label-absolute {
    position: absolute;
}

.control-content {
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.28);
}

.feature-image {
    height: 250px;border-radius: 20px;
}

.feature-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.feature-details {
    padding: 25px;
    text-align: center;
}

.feature-title {
    color: #000;
    margin: 0 auto 30px;
}

.feature-list {
    margin-top: 20px;
}

.feature-list--icon-wrap {
    font-size: 30px;
    color: #8F8D87;
}


.feature-list--icon-wrap a {
    color: inherit;
}
