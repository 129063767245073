.flag-image {
    border-radius: 10px;border:none;
}

.hero {
    background-image: url('../../assets/img/wallpaper/map.png');
    height: 110%;
    width: 100%;
    padding: 150px 0;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
}

.flag-item {
    display: flex;
    position: absolute;
}

.flag-item a {
    color: #000;
    text-decoration: none;
}

.flag-item .control__content {
    color: inherit !important;
}

.flag-item--albania {
    top: 0;
    right: -200px;
}

.flag-item--egypt {
    top: 150px;
    right: 0;
}

.flag-item--macedonia {
    top: 370px;
    right: -530px;
}

.flag-item--turkey {
    top: 420px;
    right: -270px;
}

.flag-back {
    margin-top:100px;
    border-radius: 10px;
    background-size: cover;
}

.camp-list {
    height: 450px;
    overflow-x: auto;
    display: flex;
    padding-bottom: 20px;
}

.activity-image {
    border-radius: 10px;
}

.member-image {
    border-radius:50%
}

.external-link {
    margin: 40px ; text-decoration: underline; color: #fff;font-weight: 600;
}

.external-link p {
    color: inherit;
}

.text-link {
    margin-top: 40px ; text-decoration: underline;font-weight: 600;color: #000;
}

.centered-block {
    margin: auto;
    text-align: center;
}
