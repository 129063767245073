.wrap {
    margin-top: 20px;
}

.wrap img {
    max-width: 100%;
    width: auto;
}

.review-title {
    padding:10px 0;
}

.list-wrap {
    margin-top: 20px;
}

.list-icon {
    font-size: 30px !important; color: #8F8D87 !important;
}
