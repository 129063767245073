.link-wrap {
    text-align: right;
}

.camp-list {
    height: 450px;
    overflow-x: auto;
    display: flex;
    padding-bottom: 20px;
}

.each_camp {
    margin-right: 24px;
    min-width: 290px;
}

.each_camp:last-of-type {
    margin-right: 0;
}
