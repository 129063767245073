.title {
    color: black;  margin: 5px 0;font-size: 20px; text-align: center; padding: 0px 10px;
}

.question {
    text-align: center;margin:0px;
}

.feature-area {
    height: 100%;
}

.poll_card {
    height: calc(100% - 5px);
}
