.row {
    width: 85%;margin: 0 auto;
    align-items: center;
}

.instagram-wrap {
    padding: 5px 0;
}

.instagram {
    background: linear-gradient(to bottom, #405de6 0%, #833ab4 10%, #c13584 25%, #e1306c 50%, #f56040 75%, #fcaf45 100%);
    transition-property: background;
    transition-duration: 200ms;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}

.instagram:hover {
    background: #1e2469;
}

.details {
    padding: 0;
}

.instagram-btn {
    margin-right: 2px;
    font-size: 14px;
    color: white;
    background-color: black;
    padding: 12px 20px;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 10px;
}

.block-wrap {
    padding: 0;
}

.block-large-wrap {
    padding: 0;
}
