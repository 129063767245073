
h6.subscribe-title,
.subscribe-title {
    color:#fffef2;
}

.subscribe-input-group {
    margin: 0 auto;justify-content: center;
}

.subscribe-input {
    height: 48px;
}

.subscribe-btn {
    height: 48px;
    background: linear-gradient(
            286deg
            ,#00d301,#36c275 50%,#00a562);
    border: 1px solid #00a562;
}
