.container {
    padding: 0 35px;
}

.footer-title {
    margin: 35px 0 10px;
}

.text-muted {
    margin-top: 20px;line-height: 30px;
}

.footer-list {
    margin-top: 20px;
}

.footer-list--icon-wrap {
    font-size: 30px;
}

.card-recipe {
    margin-top: 20px;
}

.copyright {
    margin: 45px auto 45px auto;
    text-align: center;
}
