.camp_intro:global(.camp_intro) {
    height: auto;
    min-height: 100vh;
}

.nav-link {
    position: absolute;
    z-index: 3;
}

.nav-link--back {
    left: -10px;
}

.nav-link--next {
    right: -5px;
}

.control {
    font-weight: 400;
    background-color: rgba(0,0,0,0.48);
    color:white;
    padding: 11px;
    font-size: 20px;
}

.control--prev {
    border-radius: 0 99em 99em 0;
}

.control--next {
    border-radius: 99em 0 0 99em;
}

.description {
    color: white;text-align:center;line-height: 26px;font-size: 16px;

    text-shadow: 1px 1px 6px rgba(0,0,0, 0.5);
}

.panel {
    padding: 20px;background: #fff;
}

.title {
    font-size: 22px;color: black; margin: 0 auto ;
}

.price-unit {
    font-size: 14px;font-weight: 100;
}

.price-description {
    color: #322e2e;
    margin: 0;
    padding: 10px 0;
    line-height: 10px;
    font-weight: 100;
    font-size: 10px;
}

.intro-link {
    color: grey; text-decoration: underline;    position: absolute;top: 20px;right: 70px;
}

.dropdown {
    position: relative;
}

.dropdown-label {
    font-weight: bold;
    font-size: 10px;
    color: black;
    padding: 5px;
    position: absolute;
    left: 20px;
    text-align: left;
}

.dropdown-btn {
    height: 50px;
}

.dropdown-btn :global(.dropdown-toggle::after) {
    margin-right: 20px;
}

.form-wrap {
    margin:10px 0;
}

.main-btn {
    width: 100%;
}

.submit-legend {
    text-align: center;font-size: 14px; color: rgb(34, 34, 34); margin: 5px 0;
}

.list-item {
    text-decoration: underline; color: rgb(34, 34, 34);
}

.list-item--text {
    font-size: 12px;
}

.list-item--price {
    text-align: right;
}

.list-item p {
    color: inherit;
    font-size: inherit;
}

.list-item--no-underline {
    text-decoration: none;
}
