.feature-area {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .05);
    color: white;
}

.member-story {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .05);
    color: white;
}

.member-body {
    padding: 25px;
    text-align: center;
}

.graph {
    height: 500px;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.graph img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}
