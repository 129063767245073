.hero {
    background-size: cover;
    background-image: url('../../assets/img/wallpaper/partners.jpeg');background-position-y: -300px
}

.container {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;
}

.hero__title {
    color: white;text-shadow: 1px 1px 6px rgba(0 0 0, 0.5);font-size: 54px;
}

.header-btn {
    display: inline-block;
    vertical-align: top;
    margin: 0 4px;
}

.form-btn {
    padding: 10px 20px;color: white !important;background: #808080e0 !important
}

.whatsapp-btn {
    padding: 10px 20px;color: white !important;
}
