.wrap {
    margin-top: 30px;
}

.title-wrap {
    width: auto;
    position: relative;
}

.title {
    margin-bottom: 50px;
    text-align: center;
}

.text-wrap {
    width: auto;
    margin: 70px auto 230px auto;
}

.wi-fi-wrap {
    height: 150px;
    position: relative;
}

.wi-fi-wrap-content {
    margin-top: 70px;visibility: visible; animation-delay: 0.4s; animation-name: fadeInRight;
}

.wi-fi-symbol {
    right:50px;top:20px;
}
