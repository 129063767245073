.grid {
    float: none;
    width: 100%;
    padding-left: 0.2em;
    padding-right: 0.2em;
    padding-bottom: 0.4em;
}
@media screen and (min-width: 40.063em) {
    .grid {
        float: left;
        width: 33.333%;
    }
}
