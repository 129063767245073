html {
    height: 100%;
    min-height: 100%;

    word-break: normal;

    font-size: 100%;
}

body {
    position: relative;

    height: 100%;
    min-height: 100%;

    color: #000;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
