.wrap {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.wrap span {
    margin-bottom: 0;
}

.overlay {

    height: 100%;
    width: 100%;
    position: absolute;

    top: 0px;
    left: 0px;
    z-index: 2;
    background: black;
    opacity: 0.5;
}

.video {
    background-size: cover;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    width: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.hero-area {
    z-index: 3;
    position: absolute;
    padding: 190px 0 0px;
}

.hero-content-wrap {
    width: auto;
    margin: 0px auto;
}

.hero-h1 {
    visibility: visible;
    animation-delay: 0.4s;
    animation-name: fadeInUp;
    color: #fff !important;
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
}

.camps-link {
    height: 48px;
    color: #00a562;
    margin-right: 4px;
    border: 1px solid white;
    background: transparent;
}

.form-link {
    height: 48px;
    background: linear-gradient(
            286deg, #00d301, #36c275 50%, #00a562);
    border: 1px solid #00a562;
    padding: 5px 20px;
}

.links-wrap {
    display: flex;
    flex-flow: row wrap;
    color: white;
    font-size: 16px;
    padding: 5px;
    font-weight: 100
}

.link-item {
    padding: 5px;
    display: flex;
    flex-flow: row wrap;
    color: white !important;
    font-size: 16px !important;
    font-weight: 100 !important;
}

.link-item--bold {
    font-weight: 500 !important;
}

.features-list-item {
    color: white;
    font-size: 12px;
}

.features-list-item span,
.features-list-item a {
    text-transform: none;
    color: inherit !important;
    font-size: inherit !important;
    margin-bottom: 0 !important;
    line-height: 1.5 !important;
}

.features-list-item:nth-of-type(1) {
    color: orange;
    line-height: 1px;
    font-size: 15px;
}

.features-list:nth-child(2) {
    color: orange;
    font-size: 12px;
    line-height: 0px
}

