.control {
    position: absolute;z-index: 999;
}

.control__content {
    color:white;
    background-color: rgba(12 242 143, 0.75);
}

.btns {
    margin:0;position: absolute;z-index: 9999;right: 10px;
}

.image_camp {
    border-radius: 20px 20px 0 0;
    overflow: hidden;
}

.image_camp img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.camp_text-wrap {
    text-align: center;padding: 0;
}

.camp_text {
    text-align: left; color: grey; text-transform: uppercase; font-size: 10px;display: block; padding: 10px 10px 0;
}

.camp_date {
    color: black;  margin: 5px 0;font-size: 20px; text-align: left; padding: 0 10px
}

.header-btn {
    padding: 5px 0px !important;
}

.btn-hover {
    width: 46%;margin-right: 2px;font-size: 14px;color: grey;
    padding: 12px;
    border: 1px solid grey;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 10px;
}

.header-price {
    width: 46%;
    margin-left: 3px;
    font-size: 14px;

    color: grey;
    padding: 7px;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 10px;
}

.header-cost {
    color:#414042; font-size: 24px;
}

.each_camp:last-of-type {
    margin-right: 0;
}

.feature-area {
    width: 290px;
    height: 100%;
}

.card_panel {
    height: calc(100% - 5px);
    border: 2px solid transparent;
}

.card_panel--active {
    background: #ffc1068c;
}

.card_panel--highlight {
    border-color: #f6c328;
}

.slider-wrapper {
    overflow: hidden;
}
